.embeddedServiceHelpButton .helpButton .uiButton {
    min-width: 70px !important;
    max-width: 70px !important;
    @apply bg-primary-0 h-full w-full block p-0 rounded-[50%] #{!important};
}
.embeddedServiceHelpButton .helpButton .uiButton:focus {
    @apply h-full w-full p-0 outline-primary-0 #{!important};

}
.embeddedServiceHelpButton .helpButton {
    @apply h-[70px] w-[70px] right-5 bottom-5 md:right-8 md:bottom-8 #{!important};
}
.embeddedServiceHelpButton .helpButton .helpButtonEnabled:hover::before, .embeddedServiceHelpButton .helpButton .helpButtonEnabled:focus::before {
    @apply bg-primary-dark-80 rounded-[50%] #{!important};
}
.embeddedServiceHelpButton .helpButton .uiButton .helpButtonLabel {
    /* display: none; */
}
.embeddedServiceHelpButton .embeddedServiceIcon {
    @apply m-0 block;
}
.embeddedServiceHelpButton .embeddedServiceIcon::before {

}