.richtext--content,
.richtext-content,
.block-richtext,
.ezrichtext-field,
.format-html {
  @import "button";
  @import "fonts";
  @import "list";
  @import "table";
  @import "media";
  :is(h1, h2, h3, h4, h5, h6){
    sup {
      font-size: initial !important;
    }
  }
}

.richtext--content {
  :is(h1, h2, h3, h4, h5, h6, p) {
    @apply mb-4 last:mb-0;
  }
}
