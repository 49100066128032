ol:not(.nav-tabs, .featured-list-content__list),
ul:not(.nav-tabs, .featured-list-content__list) {
  display: block;
  padding-inline-start: 18px;
  @apply mb-4 last:mb-0;
  
  ol,
  ul,
  ol ul,
  ul ul {
    @apply mb-2 last:mb-0;
  }

  li {
    display: list-item;
  }
}

ol:not(.nav-tabs, .featured-list-content__list) {
  list-style-type: decimal;

  & ul {
    list-style-type: circle;
  }

  & ul,
  & ol {
    & ul {
      list-style-type: square;
    }
  }
}

ul:not(.nav-tabs, .featured-list-content__list) {
  list-style-type: disc;

  & ul {
    list-style-type: circle;

    & ul {
      list-style-type: square;
    }
  }
}
