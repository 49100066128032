.footer {
  @apply bg-neutral-100 p-[14px] xs:pl-[63px] pr-[65px] pt-[57px] xs:pt-[42px] pb-[65px] xs:pb-[40px];

  &-container {
    @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 xl:container xl:mx-auto;

    &__logo {
      @apply flex shrink-0 mt-1.25;

      img {
        @apply w-[130px] h-[41px];
      }
    }

    &__list {
      @apply flex flex-col gap-5.75 text-silver xs:last:-ml-2;

      ul {
        @apply flex flex-col items-start;
      }

      a {
        @apply hover:underline;
      }

      .ezrichtext-field {
        :is(h1, h2, h3, h4, h5, h6) + * {
          @apply mt-5.75 block;
        }
      }
    }
  }
}

.footer-primary {
  @apply py-8 md:py-28 px-4 flex-col bg-blue-dark-10 text-neutral-0;
  &-wrapper {
    @apply max-w-7xl w-full mx-auto;
  }
  &-container{
    @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 md:gap-28;
    &__list {
      @apply flex flex-col gap-5 text-[15px] leading-relaxed;
      h5 {
        @apply text-[16px] font-bold;
      }
      ul {
        @apply flex flex-col gap-1;
        li {
          a {
            @apply font-medium hover:underline hover:opacity-80;
          }
        }
      }
    }
  }
  &-copyright{
    @apply pt-9 mt-9 border-t border-neutral-20/[0.5] flex flex-col items-center text-center md:flex-row md:flex-wrap md:justify-between gap-5;
  }
}