.interactive-card {
  &--main-grid {
    @apply grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-8;
  }

  &__content-cards {
    @apply flex-col gap-[27px];

    & > div:first-of-type  {
      @apply flex-col sm:flex-row;
    }

    &--figure {
      @apply w-full sm:w-1/2;

      img {
        @apply rounded-2xl w-full h-full object-cover object-center;
      }
    }

    &--data {
      @apply flex flex-col gap-6 w-full sm:w-1/2 py-0 px-[14px] sm:py-6 sm:px-0;
    }
  }
}
