.our-stores {
  &-wrapper {
    @apply grid lg:grid-cols-2 gap-4 md:gap-8;
  }

  &-article {
    @apply flex flex-col w-full overflow-hidden rounded-[0.5rem] md:rounded-[0.875rem] aspect-[350/380] min-h-[380px] md:aspect-[630/340] lg:aspect-[630/680] relative;
    @media(orientation: landscape) and(max-height: 600px){
      @apply lg:aspect-[630/450]
    }
    &::after {
      content: '';
      background: linear-gradient(to top, black, transparent);
      @apply absolute bottom-0 left-0 w-full h-80 mix-blend-multiply opacity-75;
    }
  }

  &-image {
    @apply absolute inset-0;

    img {
      @apply absolute w-full h-full object-cover object-center;
    }
  }

  &-content {
    @apply relative pt-4 px-[0.9375rem] pb-[2.375rem] md:p-7 md:pb-[35px] mt-auto flex gap-3 md:gap-6 w-full z-10 text-white items-start flex-col;
    @media(min-width: 360px){
      @apply flex-row;
    }
  }

  &-icon {
    @apply w-[50px] md:w-[112px] aspect-square relative;

    img {
      @apply absolute w-full h-full object-cover object-center rounded-full;
    }
  }
  &-info{
    @apply min-h-[152px] flex flex-col gap-3;
    @media(min-width: 360px){
      @apply w-1 flex-grow;
    }
    &-top-bar {
      @apply flex gap-5 items-center flex-wrap;
    }
  }
  &-title{
    @apply w-[4em] flex-grow text-xl md:text-3xl-alt;
  }
  &-description {
    @apply text-sm md:text-base leading-[1.3];
    @media(min-width: 768px){
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 5;
    }
  }
}