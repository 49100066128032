.full-container--calendar {
    .promo-content__section-content {
        @apply flex flex-col lg:flex-row gap-4 justify-center sm:-mx-[31px];
    }
}

.full-calendar {
    @apply bg-white grid grid-cols-1 px-3 py-5 pt-6 md:pt-8 md:px-[42px] md:pb-[29px] gap-5 md:gap-0 md:grid-cols-2 rounded-2xl shadow-[2px_2px_12px_1px_rgba(136,138,151,0.24)] md:max-w-[930px];

    &-left {
        @apply md:pr-[17px] order-1 md:order-none grid auto-rows-max gap-[1.6rem];

        &__title {
            @apply text-neutral-100 md:pt-[30px] text-left;
        }

        &__events {
            @apply grid items-center justify-between gap-[15px] text-[16px] text-neutral-100 h-[124px] content-start overflow-y-auto grid-cols-1;

            &::-webkit-scrollbar {
                @apply w-[4px];
            }

            &::-webkit-scrollbar-thumb {
                @apply rounded bg-neutral-50;
            }

            &--event {
                @apply grid grid-cols-[auto_1fr] gap-[11px];

                &-day {
                    @apply flex w-[38px] h-[38px] justify-center items-center text-primary-dark-40 bg-primary-light-100;
                }

                &-description {
                    @apply block text-[12px];
                }
            }
        }

        &__link {
            @apply flex justify-end;

            & > a {
                @apply flex items-center mr-7 gap-[0.325rem] text-primary-0;
            }

            &--icon {
                @apply block w-[16px] h-[16px];
            }
        }
    }

    &-right {
        @apply md:border-l border-neutral-40 md:pl-[16px];
    }

    &--cards {
        @apply lg:w-[35%] xl:w-[23%] flex flex-col sm:flex-row lg:flex-col gap-5;

        .infocard {
            @apply grow;
        }
    }

    &--has-cards {
        .custom-calendar {
            & .vc-day {
                @apply 2xl:mx-0;
            }
        }
    }
}

.custom-calendar {
    font-family: "Nunito", sans-serif !important;

    :first-letter {
        text-transform: uppercase;
    }

    & .vc-arrows-container {
        @apply p-0;
    }

    & .vc-arrow {
        @apply text-primary-0 w-[24.55px] h-[24px];
    }

    & .vc-header {
        @apply p-0 pb-1 #{!important};
    }

    & .vc-title {
        @apply text-neutral-100 text-[16px] leading-[1.4] #{!important};
    }

    & .vc-weeks {
        @apply p-0 gap-y-1 #{!important};
    }

    & .vc-weekday {
        @apply text-sm md:text-base leading-[1.4] md:leading-[1.4] pt-0.5 md:pt-1.5 text-neutral-80 #{!important};
    }

    & .vc-day {
        @apply text-sm md:text-base leading-[1.4] md:leading-[1.4] 2xl:w-[58px] 2xl:mx-[0.75px] min-h-[31px] md:min-h-[32px] md:h-[40px];

        &.in-next-month {
            display: none !important;
        }
    }

    & .vc-highlights {
        @apply bg-primary-light-100 #{!important};
    }

    &.vc-container {
        @apply border-0 rounded-none text-neutral-90 #{!important};
    }

    & .vc-popover-content {
        @apply bg-primary-0 text-white #{!important};
    }

    & .vc-nav-item {
        &:hover {
            @apply bg-white text-primary-0;
        }
        &.is-active {
            @apply bg-white text-primary-0 border-2 border-white #{!important};
        }
        &.is-current {
            @apply border-2 border-white #{!important};
        }
    }
}
