.gallery-video-image {
  @apply relative h-auto flex flex-col xs:flex-row items-center xs:items-end gap-[30px];

  .swiper-wrapper {
    @apply items-end;
  }

  &--arrow {
    @apply h-max relative block order-2;

    & > div {
      @apply p-1 opacity-80 rounded-lg block;
    }
    &.left {
      @apply left-0 xs:order-1 mr-12 xs:mr-0;
    }
    &.right {
      @apply right-0 ml-12 xs:ml-0 xs:order-3;

      @media screen and (max-width: 479px) {
        @apply -mt-[62px];
      }
    }
    &:disabled {
      & > div {
        @apply bg-neutral-40 text-neutral-60 #{!important};
      }
    }
  }

  &--swiper {
    @apply order-1 xs:order-2;

    &__title {
      @apply flex justify-center text-center text-xl sm:text-3xl lg:text-4xl;
    }

    &__slider {
      @apply flex flex-col gap-[28.97px] px-2;

      &-container {
        @apply relative w-full h-full flex justify-center items-center text-center;

        &--playbutton {
          @apply bg-white cursor-pointer w-10 h-10 xs:w-[67.57px] xs:h-[67.55px] sm:w-[88.58px] sm:h-[88.55px] rounded-full absolute z-30 border-[3.5px] border-primary-0 flex justify-center items-center ring-8 ring-white shrink-0;

          & > svg {
            @apply h-[22.52px] w-[22.52px] sm:h-[30.52px] sm:w-[30.52px];
          }
        }

        & > :is(img, iframe, video) {
          @apply aspect-video w-full max-w-[1000px] rounded-3xl sm:rounded-2xl min-h-[132px] md:min-h-[562px] bg-black;
        }

        & > img {
          @apply object-cover object-center;
        }
      }
    }
  }
}

.gallery-video-image-modal {
  @apply w-screen relative;

  &--content {
    @apply overflow-x-hidden overflow-y-auto fixed inset-0 z-50 p-4 mr-1 outline-none focus:outline-none max-w-[100vw] h-screen justify-center items-center flex;

    &::-webkit-scrollbar {
      @apply w-3;
    }

    &::-webkit-scrollbar-thumb {
      @apply rounded bg-neutral-80;
    }

    &__body {
      @apply relative flex flex-col w-full max-w-[1180px] m-auto bg-transparent outline-none focus:outline-none;

      @media screen and (min-width: 1440px) {
        @apply max-w-[80vw];
      }
    }
  }

  &--overlay {
    @apply fixed inset-0 z-40;
    background: rgba(85, 88, 105, 0.75);
  }

  &--close {
    @apply absolute top-5 right-5 w-10 h-10 text-neutral-40 hover:text-white flex z-10;

    span {
      @apply w-full h-full;
    }
  }

  .gallery-video-image {
    &--swiper {
      &__slider {
        &-container {
          & > img,
          & > iframe,
          & > video {
            @apply min-h-[320px] md:min-h-[480px] max-h-[45vh] md:max-h-[60vh];

            @media screen and (min-width: 1440px) {
              max-width: 80vw !important;
            }
          }
        }
      }
    }
  }
}
