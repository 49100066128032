.featured-list {
    @apply flex flex-col md:flex-row grow gap-9 md:gap-5 py-[10px];

    &__image {
        @apply w-full md:w-1/2 grow;

        figure {
            @apply h-full;

            img {
                @apply rounded-2xl object-cover w-full aspect-[550/385];
            }
        }
    }

    &-content {
        @apply w-full md:w-1/2 grow;

        &__text {
            @apply flex px-[14px] sm:px-0 flex-col justify-center text-neutral-100 gap-2 h-full;

            h3 {
                @apply font-terpel-extrabold;
            }
        }

        &__list {
            @apply grid xs:grid-cols-2 md:grid-cols-1 2lg:grid-cols-2 auto-rows-max gap-y-5 gap-x-6 text-neutral-100 px-[14px] py-[29px] md:px-9 md:py-[25px] rounded-[25px] shadow-[0_6px_40px_rgba(0,0,0,0.1)];

            &--item {
                @apply relative w-full flex gap-[14px] md:gap-[18px];

                & > span {
                    @apply w-6 h-6 shrink-0;

                    & > i {
                        @apply w-6 h-6;
                        margin-right: inherit;
                    }
                }
            }
        }
    }
}
