.breadcrumb {
    @apply inline-flex xs:flex flex-wrap gap-2 items-center pt-8 lg:ml-7;

    &>li:first-child {
        @apply mb-1 xs:mb-0;
    }

    &__item {
        @apply flex gap-2 text-primary-0 font-semibold;

        &--active {
            @apply text-neutral-80 pointer-events-none cursor-default;
        }

        &::before {
            content: "/";
            @apply text-base text-inherit font-semibold text-primary-0;
        }
    }

    &.altoque {

        li {
            @apply text-inherit font-bold font-nunito;
            i {
                width: 1em;
            }

            margin: 0;

            &::before {
                display: none;
            }
        }
        a{
            @apply text-inherit hover:underline;
        }
    }


}

.banner-breadcrumb {
    .breadcrumb.altoque {
        @apply p-0 m-0;
        li {
            @apply text-white text-[18px] leading-[1.3];
            i {
                @apply w-[19px] h-[19px];
            }
        }
    }
}