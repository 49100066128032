.pagination {
  @apply flex flex-col gap-16;

  .filters-content {
    @apply flex flex-col w-full lg:w-auto lg:flex-row gap-5 flex-1;

    .filters-content__item {
      @apply w-full lg:max-w-[455px];
    }
  }

  .pagination__view-switchers {
    @apply flex gap-4 justify-end mb-4 w-full lg:w-auto lg:mb-0;

    button {
      @apply flex items-center w-6 h-6 text-neutral-60 hover:text-primary-light-40;

      &.active {
        @apply text-primary-0;
      }
    }
  }

  &-content {

    // @apply h-10 bg-green-300;
    .grid-cols-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .grid-cols-5 {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    @screen sm {
      .sm\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }

      .sm\:grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
      }
    }

    @screen md {
      .md\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }

      .md\:grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
      }
    }
  }

  &-items {
    @apply flex justify-center items-center gap-4;

    .page-item {
      @apply text-neutral-100;

      &--prev,
      &--next {
        span {
          @apply flex items-center w-8 h-8 text-primary-0 pointer-events-none;
        }
      }

      &--active {
        @apply text-primary-0 font-bold;
      }

      &--disabled {
        @apply pointer-events-none;

        span {
          @apply text-neutral-40;

          svg path {
            @apply fill-neutral-60;
          }
        }
      }
    }
  }

  .pagination__table {
    table {
      @apply w-full border-collapse border-inherit indent-0;

      thead {
        @apply relative hidden md:table-header-group;

        tr th {
          @apply border border-y border-x-0 border-neutral-50 p-[10px];
        }

        &::after {
          content: "";
        }
      }

      tbody {
        &.media-library-skeleton {
          tr td {
            @apply p-0 md:border-4 border-white bg-gray-100;
          }
        }

        tr {
          td {
            @apply p-0 md:border-4 border-white md:bg-neutral-5 flex md:table-cell;

            div:not(.cell-responsive) {
              @apply flex bg-neutral-5 grow p-2 mb-1 ml-1 md:m-0;

              a {
                @apply flex gap-1 items-center;
              }
            }

            .cell-responsive {
              @apply flex md:hidden p-2 pb-3 border-r border-neutral-50 w-28 xxs:w-[129px] shrink-0 grow-0 text-sm leading-[1.4] font-bold items-center;
            }

            &:first-child {
              @apply md:border-l-0;

              div:not(.cell-responsive) {
                @apply justify-start text-left;
              }
            }

            &:last-child {
              @apply md:border-r-0;

              div:not(.cell-responsive) {
                @apply mb-0;
              }
            }
          }

          &:nth-child(odd) {
            div:not(.cell-responsive) {
              @apply bg-neutral-5 md:bg-opacity-0;
            }
          }

          &:nth-child(even) {
            div:not(.cell-responsive) {
              @apply bg-[#ECECEC] md:bg-opacity-0;
            }
          }
        }
      }

      th {
        @apply p-2;

        div {
          @apply flex items-center justify-between;
        }
      }
    }
  }
}