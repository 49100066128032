.video-card {
  @apply aspect-square rounded-xl relative overflow-hidden flex flex-col justify-end;
  &-overlay {
    @apply absolute inset-0 bg-gradient-to-t from-black to-transparent mix-blend-multiply;
  }
  &-imagen {
    @apply absolute inset-0 bg-neutral-20 flex;
    img {
      @apply object-cover w-full h-full;
    }
    i {
      @apply m-auto w-1/2 opacity-40;
    }
  }
  &-content {
    @apply relative z-10 flex flex-col p-2.5 md:p-5 text-white;
    button {
      @apply w-6 h-5 rounded md:w-12 md:h-10 md:rounded-xl bg-primary-0 flex mb-3 ml-auto;
      i { 
        @apply m-auto w-2 md:w-4;
      }
    }
    &__title {
      @apply text-[14px] md:text-[20px] line-clamp-2;
    }
    &__date {
      @apply text-[12px] md:text-[16px];
    }
  }
}