.grid-brands {
  @apply overflow-hidden;
  &-wrapper {
    @apply grid grid-cols-1 md:grid-cols-[0.84fr_1fr] gap-7 md:gap-[50px] -m-7 md:m-0 p-7 rounded-2xl md:bg-white md:shadow-[0px_4px_7.99px_0px_#00000021];
    &-left {
      @apply flex flex-col gap-4;
    }
    &-right {
      @apply flex-col lg:flex-row flex gap-8 lg:gap-0 items-center;
    }
  }
  &-title {
    @apply text-sm font-terpel-medium uppercase text-center lg:text-left tracking-[3px] leading-[1.35] text-[#4B4B5B];
  }
  &-content{
    @apply flex flex-col gap-4 flex-grow;
  }
  &-separate{
    @apply hidden lg:block mx-10 border-l border-neutral-50 h-20 self-center;
  }
  &-icons{
    @apply flex justify-center lg:justify-between gap-7;
    figure {
      @apply w-15 md:w-20 lg:w-auto lg:flex-grow aspect-square relative max-w-[112px];
      img {
        @apply absolute w-full h-full object-cover object-center rounded-full;
      }
    }
  }
}