.glide {
  div[data-glide-el="controls"] {
    @apply flex justify-center gap-2;
  }

  &__slide {
    @apply flex flex-col grow;
    height: auto !important;
  }

  &--banner {
    div[data-glide-el="controls"] {
      @apply absolute top-1/2 -translate-y-1/2 justify-between -inset-x-4;


      button {
        i {
          @apply text-white text-opacity-80;

          svg {
            path {
              @apply fill-primary-0;
            }
          }
        }
      }
    }
  }

  &--card-landscape {
    &:not(.is-full) {
      @apply overflow-hidden;
    }
  }

  &--card-landscape,
  &--card-services {
    .glide__slides,
    .glide__track {
      @apply overflow-visible;
    }
  }

  &--promo-card {
    &.glide.is-full {
      @apply mx-auto;
      width: calc(100% - 104px);
    }

    &.is-full.arrow-is-middle div[data-glide-el="controls"] {
      @apply mt-8 sm:mt-0 sm:absolute top-1/2 -translate-y-1/2 sm:justify-between m-0 -mx-[52px];
      width: calc(100% + 104px);
    }

    &.cols-1{
      img {
        height: 204px;
      }
    }
    &:not(.cols-1){
      .card-promo {
        @apply pb-11
      }
    }
    .card-promo {
      @apply p-3.5 h-full;
    }

    .glide__slides {
      @apply py-5;
    }
  }

  &--slider-banner {
    .glide__slides--disabled {
      .glide__slide {
        &:first-child {
          margin-left: 0 !important;
        }
        &:last-child {
          margin-right: 0 !important;
        }
      }
    }
  }

  &--card-image {
    &__item {
      figure img {
        @apply w-full aspect-[495/205] object-cover object-center rounded-2xl;
      }
    }
  }
}

.carousel {
  &-aside {
    &.full-container {
      @apply gap-[34px] md:gap-2 md:-mt-5;
    }

    div[data-glide-el="controls"] {
      @apply mt-0;
    }

    &.full-container > div:first-child {
      @apply md:w-[39%] md:py-5 shrink-0;
    }

    &.full-container > div:last-child {
      @apply md:w-[55%];
    }

    .promo-content__section-text {
      @apply text-left;

      p {
        @apply font-nunito font-normal text-base leading-[1.4];
      }
    }
  }

  &--card-image {
    @apply p-0;

    .promo-content__section-content {
      @apply flex flex-col 2md:flex-row gap-8 2md:gap-4 2md:flex-nowrap;
    }

    &__left {
      @apply w-full 2md:w-[46%] shrink-0 grow;

      figure img {
        @apply w-full;
      }
    }

    &__right {
      @apply w-full 2md:w-[54%] shrink-0 grow flex flex-col gap-10 2md:gap-[54px];

      &-description {
        @apply text-lg leading-[1.4] font-bold text-neutral-100 text-center 2md:text-right;
      }

      &-footer {
        @apply text-lg leading-[1.4] text-primary-0 text-center;
      }
    }
  }
}
