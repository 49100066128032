.double-card {
  @apply flex flex-col md:flex-row grow gap-[15px] md:gap-5;

  &__content {
    @apply flex flex-col gap-[13px] md:gap-5 w-full md:w-[48.20%] grow;

    &-card {
      @apply px-0 py-[25px] md:px-[27px] md:py-[30px] rounded-3xl basis-[50%] flex flex-col md:gap-5 gap-2.5 grow bg-white;

      h3 {
        @apply text-neutral-100 tracking-[-2%];
      }

      &__icon {
        @apply w-10 h-10;

        & + h3 {
          @apply md:-mt-4;
        }
      }

      &:not(.no-shadow) {
        @apply shadow-[0px_6px_40px_rgba(0,0,0,0.1)] px-[14px] md:px-8;
      }

      &.no-shadow + .double-card__cta {
        @apply md:pl-8;
      }
    }
  }

  &__image {
    @apply w-full md:w-[51.80%] grow;

    figure {
      @apply h-full;

      img {
        @apply rounded-2xl object-cover w-full aspect-[570/482];
      }
    }
  }

  &__cta {
    @apply flex;

    a {
      @apply w-full md:w-auto;
    }
  }
}

.full-container--double-card {
  &.is-left-title {
    @apply md:flex-row md:gap-5;

    .promo-content__section {
      &-text {
        @apply md:w-1/2 2md:w-2/5 lg:w-[25.72%] md:shrink-0 md:text-left md:pt-[50px];

        p {
          @apply font-nunito text-base;
        }
      }

      &-content {
        @apply md:w-1/2 2md:w-3/5 lg:w-[74.28%] md:flex-col;

        .double-card {
          @apply md:flex-col lg:flex-row;

          &__image {
            @apply md:w-full lg:w-[64.64%];

            figure {
              img {
                @apply aspect-[521/354];
              }
            }
          }
          &__content {
            @apply md:w-full lg:w-[35.36%];

            &-card {
              @apply md:px-4 py-4 md:gap-2;

              &__icon {
                & + h3 {
                  @apply mt-0;
                }
              }
            }
          }
          &__cta {
            @apply hidden;
          }
        }
      }
    }

    .full-container--double-card__cta {
      @apply flex justify-center md:justify-start;

      a {
        @apply grow md:grow-0;
      }
    }
  }

  &__cta {
    @apply hidden;
  }
}
