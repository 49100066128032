.card-service {
  @apply relative flex flex-col sm:flex-row rounded-2xl overflow-hidden h-full;

  &-content {
    @apply flex flex-col justify-start gap-2 pt-3 pb-4 px-[14px] sm:px-5 sm:pt-[42px] sm:pb-[26px] w-full md:w-[51.6%] flex-grow;

    &__title {
      @apply text-[#333333];
    }

    &__description {
      @apply text-[#666666];
    }

    &__cta {
      @apply rounded-2xl text-base leading-[1.4] px-6 py-[12px] bg-primary-0 text-white border border-primary-0 hover:bg-white hover:text-primary-0 cursor-pointer;
    }

    div:not(.card-service-content__title, .card-service-content__description ) {
      flex-grow: 1;
      align-items: flex-end;
    }
  }

  &-image {
    @apply w-full md:w-[48.4%] flex-grow;

    figure {
      @apply h-full aspect-[237/192] sm:aspect-auto p-[14px] pb-0 sm:p-0;

      img {
        @apply rounded-[10px] sm:rounded-r-none sm:rounded-l-2xl object-cover w-full h-full;

        &.is-full-rounded {
          @apply sm:rounded-2xl;
        }
      }
    }
  }
}
