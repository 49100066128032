.v-loader {
  @apply animate-spin h-20 w-20 text-neutral-60 fill-none m-6;

  &-wrapper {
    @apply absolute inset-y-0 left-0 w-full bg-white bg-opacity-75 flex items-center justify-center z-10;

    &--global {
      @apply fixed z-10;
    }

    &--sticky {
      &.is-top {
        @apply items-start;
      }

      &.is-bottom {
        @apply items-end;
      }

      &.is-center {
        .v-loader {
          @apply fixed left-1/2 top-1/2 -ml-10 -mt-10;
        }
      }
    }
  }
}