.button {
  @apply rounded-lg md:rounded-xl inline-block hover:shadow-md px-4 py-3 md:px-6 md:py-4 text-neutral-100 text-base leading-[1.2] text-center border transition-colors duration-300 cursor-pointer;
  &.small {
    @apply py-2 px-4 text-sm leading-[1.2];
  }
  &.button-rounded {
    @apply rounded-full py-1.5;
  }
  &-white {
    @apply bg-white hover:bg-neutral-5 border-white hover:border-silver;
  }

  &-white-primary {
    @apply bg-white text-primary-dark-40 hover:bg-neutral-5 border-white hover:border-primary-dark-40;
  }

  &-silver {
    @apply bg-silver text-neutral-100 hover:bg-white border-silver hover:border-white;
  }

  &-neutral {
    @apply bg-neutral-50 text-white hover:bg-white hover:text-neutral-100 border-neutral-50 hover:border-white;
  }

  &-primary {
    @apply text-white bg-primary-0 border-primary-0 hover:bg-primary-dark-40 hover:border-primary-dark-40;
  }

  &-primary-outline {
    @apply border-primary-0 text-primary-0 bg-white hover:bg-primary-light-100;
  }

  &-primary-dark {
    @apply bg-primary-dark-40 text-white hover:bg-white hover:text-primary-dark-40 border-primary-dark-40;
  }

  &-primary-dark-outline {
    @apply border-primary-dark-40 text-primary-dark-40 hover:bg-primary-dark-40 hover:text-white;
  }

  &-blue {
    @apply relative z-[1] bg-blue-voltex-40 bg-gradient-to-r from-blue-voltex-40 to-blue-voltex-30 text-white hover:border-blue-voltex-50;
    &::after {
      content:"";
      @apply absolute inset-0 rounded-[inherit] bg-blue-voltex-50 z-[-1] opacity-0 transition duration-300;
    }
    &:hover{
      &::after {
        @apply opacity-100;
      }
    }
  }

  &-blue-outline {
    @apply border-blue-voltex-40 text-blue-voltex-40 hover:bg-blue-voltex-10;
  }

  &-blue-dark {
    @apply bg-blue-dark-80 text-white hover:bg-white hover:text-blue-dark-80 border-blue-dark-80;
  }

  &-blue-dark-outline {
    @apply border-blue-dark-80 text-blue-dark-80 hover:bg-blue-dark-80 hover:text-white;
  }

  &-yellow {
    @apply bg-yellow-dark-80 border-yellow-dark-80 text-neutral-100 hover:bg-yellow-dark-100 hover:border-yellow-dark-100;
  }

  &-yellow-outline {
    @apply border-yellow-0 text-yellow-0 hover:bg-yellow-0 hover:text-neutral-100;
  }

  &-yellow-dark {
    @apply bg-yellow-dark-80 text-neutral-100 hover:bg-white hover:text-yellow-dark-80 border-yellow-dark-80;
  }

  &-yellow-dark-outline {
    @apply border-yellow-dark-80 text-yellow-dark-80 hover:bg-yellow-dark-80 hover:text-neutral-100;
  }

  &.disabled {
    @apply bg-neutral-50 text-neutral-0 border-neutral-50 hover:shadow-none cursor-default;
  }
  &.has-icon-right {
    i.icon {
      width: 0.9em;
      margin-left: 0.7em;
      margin-right: 0;
    }
  }
  i.icon {
    width: 1.6em;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.7em;
    margin-top: -0.5em;
    margin-bottom: -0.3em;
    height: 1.6em;
  }
}
