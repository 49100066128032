.promo-content__section .full-container--indicator {
    &.is-left-title {
        @apply 2md:flex-row gap-5 sm:gap-16 2md:gap-20 justify-center;

        .indicator {
            &-container {
                @apply 2md:w-[274px] shrink-0 pt-0;

                &__description {
                    @apply gap-6;

                    h2 {
                        @apply text-2xl leading-[1.3];
                    }

                    &-button {
                        @apply mt-4;
                    }
                }
            }
        }
    }

    .indicator {
        &-container {
            @apply flex flex-col z-10 flex-grow 2md:pt-5 pb-[30px];

            &__description {
                @apply flex flex-col justify-center gap-8 md:gap-14;

                &-text {
                    @apply -mt-1;

                    div p {
                        font-size: inherit;
                        line-height: inherit;
                    }
                }

                &-button {
                    @apply -mt-[3px] flex justify-center;
                    .button {
                        @apply w-full md:w-auto;
                    }
                }
            }
            &__slider {
                @apply w-full overflow-hidden;
            }
        }

        &-numbers {
            @apply flex flex-wrap gap-[10px] md:gap-5 justify-center;

            li {
                @apply p-1.5 pb-[15px] md:p-[10px] md:pb-[20px] w-[44px] sm:w-16 md:w-[84px] shrink-0 grow-0 bg-white font-terpel-extrabold text-[56px] sm:text-7xl md:text-[120px] leading-[0.85] rounded-md md:rounded-xl flex justify-center items-center;
            }
        }
    }
}
