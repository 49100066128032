.card-vertical {
    @apply flex flex-col rounded-2xl shadow-[0_6px_40px_rgba(0,0,0,0.1)];

    &-header {
        & > img {
            @apply object-cover w-full h-auto rounded-t-3xl;
        }
    }

    &-content {
        @apply px-[14px] md:px-8 lg:px-[57px] pt-5 md:pt-8 lg:pt-12 pb-4 md:pb-10 lg:pb-[62px] flex flex-col justify-start grow gap-3 md:gap-5 lg:gap-8;
        &__title,
        &__description {
            @apply text-neutral-100;
        }

        &__link {
            @apply flex grow items-end md:justify-end mt-5 md:m-0;

            & > a {
                @apply w-full md:w-auto;
            }
        }
    }
}

.full-container--card-vertical {
    .promo-content__section-content {
        @apply grid grid-cols-1 md:grid-cols-2 gap-x-[20px] gap-y-10;
    }

    &__footer-text {
        @apply text-center text-xl leading-[1.3] font-terpel-bold text-neutral-100;

        &.text-align-left {
            @apply text-left;
        }

        a {
            @apply text-primary-0 hover:underline;
        }
    }
}
