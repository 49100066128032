.gm-style .gm-style-iw-tc::after {
  display: none;
}

.gm-style-iw-d {
  @apply overflow-hidden #{!important};
}

.gm-style .gm-style-iw {
  font-weight: normal;
}

.gm-style .gm-style-iw-c {
  @apply px-5 py-6 rounded-3xl w-[325px] #{!important};
}

.gm-ui-hover-effect {
  @apply h-[14px] w-[14px] top-2 right-3 opacity-100 outline-none #{!important};

  & > span {
    @apply bg-neutral-60 m-0 #{!important};
  }
}

.mapViewer {
  @apply flex flex-col 2md:flex-row gap-[21px];

  &-map {
    @apply 2md:w-[646px] w-full block border rounded-2xl;
  }

  &-content {
    box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.1);
    @apply 2md:w-[455px] w-full block p-8 transition rounded-3xl;

    &__header {
      @apply flex items-center gap-2 mb-3;

      &--icon {
        @apply flex items-center w-8 h-8 text-primary-0 pointer-events-none;
      }

      &--title {
        @apply text-neutral-100;
      }
    }

    &__body {
      @apply text-base;

      &--list {
        @apply flex flex-col gap-3 max-h-[400px] overflow-x-auto;

        & li span {
          @apply cursor-pointer hover:text-primary-0;
        }

        &::-webkit-scrollbar {
          @apply w-[6px];
        }

        &::-webkit-scrollbar-thumb {
          @apply rounded bg-neutral-40;
        }
      }
    }
  }
}

.markerInfo {
  @apply flex flex-col gap-[10px];
  &-title {
    @apply text-neutral-100;
  }
  &-body {
    @apply grid grid-cols-[1fr_auto] gap-x-[9px] items-end auto-rows-min;
  }
  &-distance {
    @apply text-base bg-blue-light-100 font-semibold leading-none py-2 px-2 rounded;
  }
  &-description {
    @apply text-neutral-80 text-base leading-[22.4px] flex gap-x-2;

    &::before {
      @apply inline-flex w-[15px] h-[24px] self-stretch shrink-0 bg-no-repeat;
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg width='15.07' height='18' viewBox='241.407 239.844 17.186 21.093' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M 241.904 249.524 C 242.539 250.794 244.888 254.73 249.206 260.318 L 251.428 258.096 L 254.603 254.603 L 257.461 250.476 L 258.096 247.936 L 257.778 245.714 L 256.826 243.174 L 254.603 241.269 L 252.698 239.999 L 250.158 239.682 L 247.619 240.317 L 245.396 241.269 L 243.809 242.857 L 242.221 245.079 L 241.904 249.524 Z' fill='white' fill-opacity='0.8' style=''/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M 244.911 243.149 C 247.721 240.379 252.278 240.379 255.089 243.149 C 257.9 245.918 257.9 250.408 255.089 253.177 C 255.078 253.187 255.068 253.198 255.058 253.209 L 249.413 259.446 L 250.586 260.477 L 256.216 254.256 C 259.625 250.881 259.619 245.422 256.2 242.054 C 252.775 238.679 247.224 238.679 243.799 242.054 C 240.387 245.416 240.375 250.859 243.763 254.236 L 246.051 257.157 L 247.295 256.211 L 244.977 253.251 C 244.956 253.225 244.934 253.2 244.91 253.177 C 242.1 250.408 242.1 245.918 244.911 243.149 Z M 250 249.769 C 250.953 249.769 251.726 249.008 251.726 248.068 C 251.726 247.129 250.953 246.367 250 246.367 C 249.046 246.367 248.273 247.129 248.273 248.068 C 248.273 249.008 249.046 249.769 250 249.769 Z M 253.298 248.068 C 253.298 249.863 251.821 251.318 250 251.318 C 248.178 251.318 246.701 249.863 246.701 248.068 C 246.701 246.273 248.178 244.818 250 244.818 C 251.821 244.818 253.298 246.273 253.298 248.068 Z' fill='%23ec000f'/%3E%3C/svg%3E");
    }
  }

  &-footer {
    @apply text-neutral-60 flex flex-col gap-2 justify-between;

    &__info {
      @apply flex items-center gap-2;

      &--icon {
        @apply flex items-center w-5 h-5 text-primary-0 pointer-events-none;

        &.phone {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.1195 14.1919C14.0981 14.1872 14.1356 14.1915 14.1195 14.1919C13.7031 14.2043 12.5581 15.6276 12.0242 15.3588C11.4901 15.0902 9.6661 10.2808 9.91714 9.72931C10.1682 9.17808 11.491 9.79463 11.6974 9.34171C11.9036 8.8888 12.3111 7.08974 11.6782 5.86115C11.6782 5.86115 5.65876 5.21159 8.71895 13.3868C10.096 17.0662 11.8509 18.2819 13.3419 18.4458' stroke='%23ec000f' stroke-width='1.3' stroke-miterlimit='10' stroke-linejoin='round'/%3E%3Cpath d='M14.1195 14.1927C17.0047 16.85 16.3133 19.6345 16.1026 20.3795L16.081 20.4356C14.8919 21.0001 13.5619 21.316 12.158 21.316C7.1002 21.316 3 17.2158 3 12.158C3 7.1002 7.1002 3 12.158 3C17.2158 3 21.316 7.1002 21.316 12.158C21.316 14.1008 20.7108 15.9022 19.6791 17.3844' stroke='%23ec000f' stroke-width='1.3' stroke-miterlimit='10' stroke-linejoin='round'/%3E%3C/svg%3E");
        }
        &.clock {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.0528 7.46417L10.9795 13.4125L14.5688 11.1122' stroke='%23ec000f' stroke-width='1.5' stroke-miterlimit='10' stroke-linejoin='round'/%3E%3Cpath d='M16.779 3.509C19.4467 5.216 21.215 8.20533 21.215 11.6073C21.215 16.9137 16.9137 21.215 11.6073 21.215C6.30133 21.215 2 16.9137 2 11.6073C2 6.30133 6.30133 2 11.6073 2V4.16967' stroke='%23ec000f' stroke-width='1.5' stroke-miterlimit='10' stroke-linejoin='round'/%3E%3Cpath d='M11.6075 19.041V21.211' stroke='%23ec000f' stroke-width='1.5' stroke-miterlimit='10' stroke-linejoin='round'/%3E%3Cpath d='M2 11.6059H4.16633' stroke='%23ec000f' stroke-width='1.5' stroke-miterlimit='10' stroke-linejoin='round'/%3E%3Cpath d='M19.049 11.6059H21.2153' stroke='%23ec000f' stroke-width='1.5' stroke-miterlimit='10' stroke-linejoin='round'/%3E%3C/svg%3E");
        }
      }

      &--text {
        @apply text-base leading-tight w-1 flex-grow;
      }
    }
  }
  &-price {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    &_item {
      display: flex;
      flex-direction: column;
      font-size: 15px;
      gap:4px;
      .name {
        font-size: 0.85em;
      }

      .price {
        font-weight: 700;
      }
    }
  }
}

// custom select
.map-filters {
  &_filter {
    @apply w-full flex justify-end;

    &-content {
      @apply w-full bg-white border-[1px] rounded-[8px] py-[8px] px-[12px];

      &__label {
        @apply block text-neutral-80 text-[12px];
      }

      &__select {
        @apply w-[96%] outline-none h-full border-transparent bg-transparent border-0 text-neutral-100 appearance-none bg-no-repeat bg-right-top bg-[length:10.67px_6.67px];
        background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 1c0-.28-.22-.5-.5-.5h-7a.495.495 0 0 0-.37.83l3.5 4c.09.1.22.17.37.17s.28-.07.37-.17l3.5-4c.08-.09.13-.2.13-.33Z' fill='%2342535c'/%3E%3C/svg%3E");
      }
    }
  }
}
