.grid-logo {
  @apply w-full flex flex-wrap justify-center;

  &-item {
    @apply w-full p-5 flex flex-col items-center gap-5;

    a {
      @apply flex items-center justify-center;

      &:not([href]) {
        @apply cursor-default;
      }

      &.logo-img {
        @apply w-full h-full;
      }
    }

    &__description {
      @apply bg-transparent font-bold text-lg text-center leading-[23.5px] shadow-none p-0 rounded-none;

      &[href] {
        @apply text-blue-dark-80 underline;
      }

      &.not-link {
        @apply text-neutral-100 cursor-default no-underline pointer-events-none;
      }
    }

    &__card {
      @apply p-[10px];

      .logo-img {
        @apply bg-neutral-0 shadow-[1px_6px_30px_rgba(0,0,0,0.1)] hover:shadow-[1px_6px_30px_rgba(0,0,0,0.15)] rounded-2xl p-3;

        img {
          @apply w-full h-auto object-cover object-center;
        }
      }
    }
  }

  &__columns-2 {
    @apply lg:px-40;
  }

  &__columns-2 &-item {
    @apply w-1/2;
  }
  &__columns-3 &-item {
    @apply w-1/2 xs:w-1/3;
  }
  &__columns-4 &-item {
    @apply w-1/2 xs:w-1/3 sm:w-1/4;
  }
  &__columns-5 &-item {
    @apply w-1/2 xs:w-1/3 sm:w-1/4 2md:w-1/5;
  }
  &__columns-6 &-item {
    @apply w-1/2 xs:w-1/3 sm:w-1/4 2md:w-1/5 lg:w-1/6;
  }
  &__columns-7 &-item {
    @apply w-1/2 xs:w-1/3 sm:w-1/4 2md:w-1/5 lg:w-1/6 xl:w-[14.285%];
  }
}

.full-container {
  &--logo-grid__footer-text {
    @apply lg:text-lg lg:leading-[1.4] font-bold text-center text-blue-dark-80 -mt-[28px];

    &.text-align-left {
      @apply text-left;
    }
  }
}
