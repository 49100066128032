.grill {
    &-content {
        @apply flex flex-col px-0 sm:px-8 md:px-14 lg:px-[81px] xl:px-[91px] py-8
    }

    $self: &;

    @apply grid grid-flow-col gap-5 grid-cols-6;

    &--items-1 {
        @apply grid-cols-1;
        #{ $self }__item--0 {
            .grill-card {
                @apply flex-col sm:flex-row;
                &__content,
                &__image {
                    @apply sm:basis-[50%] sm:h-full;
                }
            }
        }
    }

    &--items-2 {
        @apply grid-rows-2 grid-cols-1 sm:grid-cols-2 sm:grid-rows-none;
    }

    &--items-3 {
        @apply grid-rows-2 md:grid-rows-none grid-flow-row;
        #{ $self }__item--0 {
            @apply col-span-2 md:col-span-1;
        }
        #{ $self }__item--1 {
            @apply col-span-4 md:col-span-2;
        }
        #{ $self }__item--2 {
            @apply col-span-6 md:col-span-3;
        }
    }

    &--items-4,
    &--items-5 {
        @apply grid-rows-4 md:grid-rows-[1.05fr_0.95fr] grid-flow-row-dense;

        #{ $self }__item--0 {
            @apply col-span-2 md:col-span-1 row-span-2;
        }
        #{ $self }__item--1 {
            @apply col-span-4 md:col-span-2 row-span-2;
        }
        #{ $self }__item--2 {
            @apply col-span-4  md:col-span-2;
        }
        #{ $self }__item--3 {
            @apply col-span-4  md:col-span-2;
        }
        #{ $self }__item--4 {
            @apply col-span-2 md:col-span-1 row-span-2;
        }
    }

    &--items-4 {
        #{ $self }__item--2,
        #{ $self }__item--3 {
            @apply col-span-6 md:col-span-3;
        }
    }

    &__item {
        &--2 {
            @apply h-full;
        }
    }
}

.grill-card {
    $self: &;
    @apply flex flex-col gap-5 h-full;

    &__content {
        @apply items-center;
        h3 {
            @apply text-center p-2;
        }
    }

    &__image {
        @apply h-full;
        img {
            @apply h-full w-full  object-cover;
        }
    }

    &--0 {
        #{ $self }__content {
            @apply bg-neutral-100;
        }
    }

    &--0,
    &--4 {
        #{ $self }__content {
            @apply h-1/2 items-center flex justify-center rounded-2xl;
        }
        #{ $self }__image {
            @apply rounded-2xl;
            height: calc(50% - 20px);

            img {
                @apply rounded-2xl;
            }
        }
    }

    &--1 {
        #{ $self }__content {
            @apply hidden opacity-0;
        }

        #{ $self }__image {
            @apply rounded-2xl h-full;

            img {
                @apply rounded-2xl;
            }
        }
    }

    &--2 {
        @apply flex-row gap-0 w-full;

        #{ $self }__content {
            @apply flex rounded-l-2xl justify-center bg-blue-0;
            width: calc(50% - 7px);
        }
        #{ $self }__image {
            @apply rounded-r-2xl;
            width: calc(50% + 7px);

            img {
                @apply rounded-r-2xl aspect-[171/211];
            }
        }
    }

    &--3 {
        #{ $self }__content {
            @apply h-full flex rounded-2xl justify-center bg-primary-0;
        }

        #{ $self }__image {
            @apply hidden opacity-0;
        }
    }

    &--4 {
        #{ $self }__content {
            @apply bg-yellow-dark-80;
        }
    }

    //color del texto
    &--0,
    &--3 {
        #{ $self }__content {
            @apply text-neutral-0;
        }
    }

    &--2 {
        #{ $self }__content {
            @apply text-neutral-100;
        }
    }

    &--4 {
        #{ $self }__content {
            @apply text-primary-dark-80;
        }
    }
}

.grill-redesign{
    @apply grid grid-cols-2 gap-4 md:grid-cols-4 md:gap-6;
    &-card {
        @apply relative overflow-hidden rounded-lg md:rounded-[10px] aspect-[300/450] bg-neutral-100 hover:scale-[1.2] transition duration-500 hover:z-10;
        figure {
            @apply relative w-full h-full;
            img {
                @apply absolute w-full h-full left-0 top-0 object-center object-cover;
            }
        }
        &-item-1{
            @apply bg-blue-0;
        }
        &-item-2{
            @apply bg-primary-0;
        }
        &-item-3{
            @apply bg-yellow-dark-80;
        }
    }
    &-overlay{
        @apply p-5 md:p-8 absolute inset-x-0 bottom-0 top-1/2 flex flex-col justify-end bg-gradient-to-t from-neutral-100 text-neutral-0;
        &-item-1{
            @apply from-blue-0 text-neutral-100;
        }
        &-item-2{
            @apply from-primary-0 text-neutral-0;
        }
        &-item-3{
            @apply from-yellow-dark-80 text-primary-dark-80;
        }
    }
    &-title{
        @apply leading-[1.2em] min-h-[2.4em] text-[24px] md:text-[30px] xl:text-[35px] ;
    }
}