.pie {
  cursor: pointer;
}
.overlay {
  opacity: 0;  
  transition: ease-out 0.2s opacity;
}

.pie.open, .pie.open .overlay,
.pie:hover .overlay {
  opacity: 1;
}

.circ-text{
  font-size: 25px;
  fill: #fff;
  font-weight: 700;
  font-family: "sans-serif", "Calibri";
  &.level-2{
    font-weight: 500;
  }
}
.line-divide{
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 5px;
}
.pie-text {
    width: 8.5em;
    font-size: 0.8em;
    transform: translate(-50%, -50%);
    transition: ease-out 0.2s opacity;
    @apply absolute top-0 left-0 text-center pointer-events-none opacity-0 text-white;
    &.open, &.hover{
      @apply opacity-100;
    }
    &:nth-child(1){
        margin-top: 24%;
        margin-left: 44.7%;
    }
    &:nth-child(2){
        margin-top: 32.5%;
        margin-left: 37.8%;
        width: 6em;
    }
    &:nth-child(3){
        margin-top: 42.8%;
        margin-left: 40.2%;
    }
    &:nth-child(4){
        margin-top: 49%;
        margin-left: 50.3%;
    }
    &:nth-child(5){
        margin-top: 44%;
        margin-left: 61%;
    }
    &:nth-child(6){
        margin-top: 33%;
        margin-left: 63%;
    }
    &:nth-child(7){
        margin-top: 23.5%;
        margin-left: 56.2%;
    }
    &:nth-child(8){
      margin-top: 36%;
      margin-left: 50.5%;
      width: 10em;
      font-size: 1em;
    }
}
.card-text {
    width: 28em;
    padding: 1em;
    border-radius: 0.4em;
    box-shadow: 0.2em 0.2em 1em #00000026;
    z-index: 10;
    transition: ease-out 0.2s opacity;
    @apply inline-flex flex-col gap-[1em] absolute top-0 left-0 bg-white opacity-0 pointer-events-none;
    &.open{
      @apply opacity-100 pointer-events-auto;
    }
    &:nth-child(1){
      margin-top: 4%;
      margin-left: 6%;
    }
    &:nth-child(2){
      margin-top: 20%;
      margin-left: 1%;
    }
    &:nth-child(3){
      margin-top: 42%;
      margin-left: 3%;
    }
    &:nth-child(4){
      margin-top: 48%;
      margin-left: 30%;
    }
    &:nth-child(5){
      margin-top: 42%;
      margin-left: 59%;
    }
    &:nth-child(6){
      margin-top: 19%;
      margin-left: 59%;
    }
    &:nth-child(7){
      margin-top: 6%;
      margin-left: 55%;
    }
  .card-icon{
    width: 1.3em;
    @apply flex items-center aspect-square;
    svg{
      @apply w-full h-auto;
    }
  }
  .card-header{
    font-size: 1.1em;
    @apply flex gap-[1em] font-bold;
  }
  .card-body{
    font-size:0.7em;
    @apply flex flex-col gap-[1em];
  }
  .card-link {
    font-size:0.7em;
    @apply text-white py-[0.5em] px-[1em] rounded-full bg-primary-0 leading-none;
  }
}
.info-graphic{
  @apply text-neutral-80;
  position: absolute;
  font-size: 1em;

  z-index:2;
  top: 0;
  left: 0;
  text-align: center;
  &:nth-child(1){
    margin-top: 5.3%;
    margin-left: 24%;
    transform: translateX(-100%);
  }
  &:nth-child(2){
    margin-top: 23%;
    margin-left: 11%;
    transform: translateX(-50%);
    width: 9em;
  }
  &:nth-child(3){
    margin-top: 57.5%;
    margin-left: 18.5%;
    transform: translateX(-50%);
    width: 10em;
    b{
      display:block;
    }
  }
  &:nth-child(4){
    margin-top: 6.3%;
    margin-left: 77%;
    width: 12em;
  }
  &:nth-child(5){
    @apply text-primary-0;
    margin-top: 45%;
    margin-left: 90.2%;
    width: 10em;
    transform: translateX(-50%);
  }
  &:nth-child(6){
    margin-top: 61%;
    margin-left: 77.3%;
    transform: translateX(-50%);
    width: 10em;
  }
}