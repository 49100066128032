.full-container--bvc-stats {
  .promo-content {
    &--emissions {
      @apply flex flex-col 2md:flex-row gap-3 md:gap-5 bg-blue-dark-40 text-neutral-100 rounded-[10px] 2md:rounded-2xl p-[14px] md:p-5 w-full min-h-[285px];

      &-left {
        @apply rounded-[20px] w-full 2md:w-1/2 grow bg-neutral-0;
      }

      &-right {
        &.bvc-skeleton{
          @apply w-full;

          [class^="microcopy"],
          & > div:first-child {
            @apply bg-gray-100 text-gray-100;
          }
        }

        @apply w-full 2md:w-1/2 grow flex flex-col justify-between md:justify-start gap-5;

        [class^="microcopy"] {
          @apply flex flex-col bg-neutral-0 rounded-xl 2md:rounded-2xl px-[18px] pt-2 pb-[11px] 2md:px-[19px] 2md:pt-[15px] 2md:pb-[18px] whitespace-nowrap;
        }

        .microcopy {
          &-closing-price {
            @apply pt-[14px] pb-[19px] 2md:pb-[21px];

            span {
              @apply 2md:text-sm leading-[1.4];
            }
            p {
              @apply font-terpel-extrabold text-3xl sm:text-[42px] 2md:text-[50px] text-right leading-[1] md:leading-[.75] ml-1 -mt-1 2md:mt-[0];
            }
          }

          &-date {
            @apply w-1/2 md:w-[57.5%];
          }
          &-variation {
            @apply w-1/2 md:w-[45%];
          }

          &-date,
          &-variation {
            span {
              @apply 2md:text-sm leading-[1.4];
            }
            p {
              @apply w-full text-[18px] 2md:text-xl 2md:leading-[1] font-terpel-bold 2md:text-right -mt-[3px] md:mt-0;
            }
          }
        }

        @media screen and (max-width: 374px) {
          .microcopy {
            &-closing-price + div {
              @apply flex-wrap;
            }

            &-date,
            &-variation {
              @apply w-full;
            }
          }
        }
      }

      iframe {
        @apply rounded-[20px];
      }
    }
  }
}
