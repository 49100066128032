.interactive-card {
  @apply p-[14px] sm:p-5 rounded-2xl overflow-hidden relative min-h-[242px] flex;

  &__figure {
    @apply absolute inset-0 w-full h-full;

    img {
      @apply w-full h-full object-cover object-center;
    }

    &::after {
      content: '';
      @apply absolute inset-0 w-full h-full;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #262641 100%);
      mix-blend-mode: multiply;
    }
  }

  &__content {
    @apply flex flex-col gap-4.5 relative self-end;
    width: calc(100% - 60px);

    h3,
    &-description {
      @apply text-white;
    }
  }

  &__button {
    @apply absolute right-5 bottom-5 w-[52px] h-12 flex items-center justify-center bg-primary-0 text-white rounded-2xl;
  }
}