/*

  Fonts
  ----------------------------------------------------------------------------

  Use this file to register all the fonts for the project using @font-face or Google fonts.
  After registering your fonts, it is recommended that you create variables so it's easier to
  use the fonts in your rules.

  For example, if you load Open Sans, at the end of this file create a variable like:
  $font-open-sans: 'Open Sans';

*/

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800&family=Poppins:wght@300;400;500;600;700&display=swap');


@font-face {
  font-family: 'TerpelSans-Black';
  font-display: swap;
  src:
    url('./fonts/TerpelSans/Terpel-Sans-Black-Condensed.woff2') format('woff');
}

@font-face {
  font-family: 'TerpelSans-Bold';
  font-display: swap;
  src:
    url('./fonts/TerpelSans/Terpel-Sans-Bold-Condensed.woff2') format('woff');
}

@font-face {
  font-family: 'TerpelSans-ExtraBold';
  font-display: swap;
  src:
    url('./fonts/TerpelSans/Terpel-Sans-ExtraBold-Condensed.woff2') format('woff');
}

@font-face {
  font-family: 'TerpelSans-Light';
  font-display: swap;
  src:
    url('./fonts/TerpelSans/Terpel-Sans-Light.woff2') format('woff');
}

@font-face {
  font-family: 'TerpelSans-Medium';
  font-display: swap;
  src:
    url('./fonts/TerpelSans/Terpel-Sans-Medium.woff2') format('woff');
}

@font-face {
  font-family: 'TerpelSans-Regular';
  font-display: swap;
  src:
    url('./fonts/TerpelSans/Terpel-Sans-Regular.woff2') format('woff');
}

body {
  font-size: 16px;
  line-height: 1.4;
  @apply font-terpel-medium text-neutral-80 #{!important};
}

.title.is-1,
h1 {
  @apply text-3xl-alt lg:text-4xl-alt;
}

.title.is-2,
h2 {
  @apply text-3xl-alt;
}

.title.is-3,
h3 {
  @apply text-2xl lg:text-2xl-alt;
}

.title.is-4,
h4 {
  @apply text-xl lg:text-2xl;
}

.title.is-5,
h5 {
  @apply text-xl;
}

.title.is-6,
h6 {
  @apply text-base;
}

.title.is-1,
.title.is-2,
.title.is-3,
.title.is-4,
.title.is-5,
.title.is-6,
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-terpel-bold;
}

.title.is-1,
.title.is-2,
.title.is-3,
h1,
h2,
h3 {
  @apply leading-[1.2] #{!important};
}

.title.is-4,
.title.is-5,
.title.is-6,
h4,
h5,
h6 {
  @apply leading-[1.3] #{!important};
}