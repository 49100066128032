.offer-cards {
  &-wrapper {
    @apply grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-8;
  }
  .button {
    @apply self-start;
  }
}
.offer-highlighted {
  $this: &;

  .block-section {
    &-title {
      .altoque & {
        @apply text-[60px];
      }
    }
  }

  &-wrapper {
    @apply grid gap-5 md:grid-cols-3 lg:grid-cols-4 md:gap-8;
    &.has-flexcard{
      #{$this}-article{
        @apply col-span-1 md:col-span-2 lg:col-span-3;

        .altoque & {
          @apply col-span-2 lg:col-span-3 md:gap-8;
        }
      }

      .altoque & {
        @apply grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-8;
      }
    }
  }
  &-image{
    @apply absolute inset-0;
    img {
      @apply w-full h-full object-cover object-center;
    }
  }
  &-content{
    @apply mt-auto relative z-10 w-full max-w-[300px] flex flex-col gap-4 md:gap-5;
  }
  &-title{
    @apply font-terpel-bold text-xl md:text-[30px];
  }
  &-info{
    @apply md:text-lg;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
  }
  .button {
    @apply self-start;
  }
  &-article{
    @apply relative flex flex-col overflow-hidden rounded-2xl min-h-[180px] md:min-h-[430px] p-5 md:p-11 text-white;
    &::after {
      content: '';
      background: linear-gradient(to right, black, transparent);
      @apply absolute top-0 left-0 w-full lg:w-2/3 h-full mix-blend-multiply opacity-75;
    }
    &:not(.offer-cards){
      @apply col-span-4 md:p-11 lg:p-20;
      #{$this}-title{
        @apply lg:text-[45px];
      }
      #{$this}-content{
        @media(orientation: landscape) and(max-height: 600px){
          grid-template-columns: auto 1fr;
          @apply grid items-end;

        }
        @apply mt-0 max-w-[480px];
      }
      #{$this}-info{
        @apply md:text-xl;
        -webkit-line-clamp: 3;
        @media(orientation: landscape) and(max-height: 600px){
          @apply col-span-2;
        }
      }
    }
    &:is(.banner-altoque){
      @apply min-h-[280px] md:min-h-[680px] py-5 md:py-20;
      @media(orientation: landscape) and(max-height: 600px){
        @apply md:min-h-[400px]
      }
      #{$this}-icon{
        @apply w-[50px] md:w-[160px] aspect-square relative md:mb-10;
        @media(orientation: landscape) and(max-height: 600px){
          @apply mb-0 w-[140px];
        }
        img {
          @apply absolute w-full h-full rounded-full;
        }
      }
      #{$this}-title{
        @apply md:text-[37px];
      }
      #{$this}-image{
        @apply absolute inset-0;
        img {
          @apply w-full h-full object-cover object-center;
        }
      }
    }
    .altoque & {
      @apply justify-center;
    }
  }
}