$gap: 1.25rem;
$bg-error: #fef0f0;

.form-container,
.custom-form form {
  @apply max-w-full p-4 pb-px flex flex-wrap;
  gap: $gap;

  .is-medium,
  .is-medium-child > :first-child {
    @media (min-width: 640px) {
      max-width: calc(50% - ($gap / 2));
    }
  }

  a[href] {
    @apply underline;
  }
}

.custom-form {
  span.error-message-validate {
    @apply text-primary-0 text-sm;
  }
  .form-checkbox-group,
  .form-radio-group {
    @apply flex flex-wrap gap-x-7 gap-y-3;
  }
}

.form-group {
  @apply flex flex-col gap-5 w-full;

  .error-label {
    @apply flex items-center gap-[10px] text-sm leading-[1.4] text-primary-error;

    &__icon {
      @apply flex w-[18px] h-[18px];

      svg > path {
        @apply fill-primary-error;
      }
    }
  }
}
.form-table-group {
  label {
    @apply text-base leading-[1.3] text-neutral-80;
  } 
}
.form-input-group,
.form-select-group {
  @apply flex flex-col border border-neutral-50 px-4 py-2 bg-white rounded-lg min-h-[56px] w-full;

  label {
    @apply text-xs leading-[1.3] text-neutral-80;
  }

  & :is(input, textarea) {
    @apply w-full h-full focus:outline-none text-neutral-100;
  }

  textarea {
    @apply min-h-[80px];
  }
}

.form-select-group {
  @apply relative;

  select {
    @apply absolute pointer-events-none opacity-0;
  }

  .select-selected {
    @apply w-full h-full text-neutral-100 bg-white cursor-pointer flex items-center gap-4 justify-between;

    * {
      @apply pointer-events-none;
    }

    i {
      @apply flex items-center w-4 h-4 text-neutral-80;
    }

    &.is-placeholder {
      @apply text-neutral-60;
    }
  }

  &.open {
    @apply rounded-b-none;
  }

  &:not(.open) {
    .custom-select {
      @apply hidden;
    }
  }

  .custom-select {
    @apply absolute top-full bg-white border border-neutral-50 border-t-0  -mx-[17px] rounded-lg rounded-t-none z-20;
    width: calc(100% + 2px);

    ul {
      @apply mx-2 mb-4 px-2 max-h-[19.25rem] overflow-x-hidden overflow-y-auto;

      &::-webkit-scrollbar {
        @apply w-[4px];
      }

      &::-webkit-scrollbar-thumb {
        @apply rounded bg-neutral-50;
      }

      li {
        @apply text-neutral-100 rounded-lg p-2 -mx-2 cursor-pointer hover:bg-neutral-bg select-none;

        &[disabled] {
          @apply pointer-events-none text-opacity-40;
        }
      }
    }
  }
}

.form-message {
  @apply -mt-2.5 px-2.5 text-sm leading-snug text-blue-700;
}

.form-checkbox-group,
.form-radio-group {
  @apply w-full;

  p {
    @apply mb-2;
  }

  label {
    @apply mb-2 cursor-pointer;

    & + div {
      @apply flex flex-wrap gap-5;
    }
  }

  input {
    @apply absolute w-5 h-5 opacity-0 pointer-events-none;
  }

  input + label {
    @apply relative pl-8 hover:before:border-blue-dark-20 select-none mb-0;

    &::before,
    &::after {
      content: "";
      @apply transition-all duration-200 absolute left-0 cursor-pointer;
    }
    &::before {
      @apply w-5 h-5 bg-white border-[1.5px] border-neutral-60 rounded hover:border-blue-dark-20 bg-center bg-[length:85%_auto] bg-no-repeat;
    }

    &::after {
      @apply opacity-0;
    }
  }

  input:checked {
    & + label::before {
      @apply bg-blue-dark-20 border-blue-dark-20;
    }

    & + label::after {
      @apply opacity-100;
    }
  }

  &.has-error {
    & > label {
      @apply text-primary-error;
    }
  }
}

.form-radio-group,
.form-checkbox-group.is-radio-button {
  input + label {
    &::before {
      @apply rounded-full;
    }
    &::after {
      @apply left-[5px] top-[5px] w-[10px] h-[10px] rounded-full bg-white;
    }
  }
}

.form-checkbox-group:not(.is-radio-button),
.form-radio-group.is-checkbox-button {
  input + label {
    &::before {
      @apply rounded;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 10'%3E%3Cpath d='M12 0.833496C11.65 0.833496 11.3375 0.970996 11.1125 1.196L5.75 6.571L2.8875 3.696C2.6625 3.471 2.35 3.3335 2 3.3335C1.3125 3.3335 0.75 3.896 0.75 4.5835C0.75 4.9335 0.8875 5.246 1.1125 5.471L4.8625 9.221C5.0875 9.446 5.4 9.5835 5.75 9.5835C6.1 9.5835 6.4125 9.446 6.6375 9.221L12.8875 2.971C13.1125 2.746 13.25 2.4335 13.25 2.0835C13.25 1.396 12.6875 0.833496 12 0.833496Z' fill='white' /%3E%3C/svg%3E");
    }
    &::after {
      @apply hidden;
    }
  }
}

.form-file-group {
  @apply w-full flex flex-wrap;

  input {
    @apply opacity-0 pointer-events-none absolute;
  }

  label {
    @apply flex flex-col gap-5 relative w-full bg-neutral-5 rounded-[14px] border border-neutral-50 border-dashed px-6 py-[45px] text-center text-neutral-100 cursor-pointer transition ;
    :empty {
      @apply hidden;
    }
    .is-help{
      @apply -mt-2.5 text-sm italic;
    }
    span {
      @apply text-primary-0;
    }
  }

  .is-help-file {
    @apply my-2 md:mt-7;
  }

  span {
    @apply text-neutral-80;
  }

  &.has-error {
    label {
      @apply border-primary-error;
      background-color: $bg-error;
    }
  }
}

.form-title {
  @apply w-full;

  h3 {
    @apply text-center text-xl lg:text-2xl;
  }

  h5 {
    @apply text-center;
  }
}

.form-habeas-data {
  @apply w-full;
}

.form-submit-group {
  @apply flex justify-center w-full;
}

.has-error:not(.form-radio-group, .form-checkbox-group, .form-file-group) {
  @apply border-primary-error;
  background-color: $bg-error;

  .custom-select {
    @apply border-primary-error;
  }

  input,
  textarea,
  .select-selected {
    @apply bg-transparent;
  }
}

.is-help {
  @apply text-blue-600 #{!important};

  &::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18' %3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0.5C3.85625 0.5 0.5 3.85625 0.5 8C0.5 12.1438 3.85625 15.5 8 15.5C12.1438 15.5 15.5 12.1438 15.5 8C15.5 3.85625 12.1438 0.5 8 0.5ZM8.9375 3.3125V5.1875H7.0625V3.3125H8.9375ZM6.125 11.75V12.6875H9.875V11.75H8.9375V6.125H6.125V7.0625H7.0625V11.75H6.125Z' fill='%234065F6' /%3E%3C/svg%3E");
  }
}

.is-handle-manual-error {
  @apply text-sm text-primary-error;

  &::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18' %3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0.5C3.85625 0.5 0.5 3.85625 0.5 8C0.5 12.1438 3.85625 15.5 8 15.5C12.1438 15.5 15.5 12.1438 15.5 8C15.5 3.85625 12.1438 0.5 8 0.5ZM8.9375 3.3125V5.1875H7.0625V3.3125H8.9375ZM6.125 11.75V12.6875H9.875V11.75H8.9375V6.125H6.125V7.0625H7.0625V11.75H6.125Z' fill='%23d8324b' /%3E%3C/svg%3E");
  }
}

.is-help,
.is-handle-manual-error {
  @apply relative -mt-3 pl-6;

  &::before {
    content: "";
    @apply absolute left-0 w-[18px] h-[18px] bg-opacity-0 bg-no-repeat bg-cover m-0.5;
  }
}

.is-hidden {
  @apply hidden opacity-0;
}

.form-steps {
  @apply flex flex-col gap-6;
  .tabs-steps {
    @apply flex gap-5 justify-center;
    .form-step {
      @apply py-2 px-5 font-terpel-bold cursor-pointer relative;
      &.current { @apply font-terpel-extrabold text-primary-0 border-b-2 border-primary-0;}
      &.progress {@apply text-primary-0;}
      &.disabled {@apply pointer-events-none grayscale opacity-30;}
    }
  }
  .content-steps {
    .content-step {
      @apply flex flex-col gap-4;
    }
  }
  .steps-tools {
    @apply grid grid-cols-3 gap-5 bottom-0 bg-white z-10 py-4 border-t border-neutral-50;
    & > div {
      @apply flex;
    }
    button {
      @apply text-[15px] md:text-[20px] text-center w-full md:w-auto inline-block rounded-full py-1.5 md:py-2 px-5 transition border-2 font-bold leading-none;
      &.before-btn{
        @apply mr-auto;
      }
      &.next-btn{
        @apply ml-auto;
      }
    }
  }
}

.disabled[class*="form-"]{
  @apply pointer-events-none opacity-50;
}

.form-register {
  @apply py-10 px-4 md:py-20 md:px-0 text-sm md:text-base;
  &-title{
    @apply text-primary-0 text-[20px] md:text-[30px];
  }
  &-wrapper{
    @apply max-w-[460px] mx-auto rounded-2xl p-6 md:p-10 flex flex-col gap-5 overflow-hidden shadow-[2px_2px_12px_1px] shadow-neutral-60/25; 
    .form-container {
      @apply p-0;
      .reset-password {
        label {
          @apply ml-auto mb-0 -mt-2;
        }
      }
      .form-checkbox-group, .form-radio-group {
        @apply flex-col gap-0 md:gap-5 md:flex-row;
        label {
          @apply text-sm;
          &::before{
            @apply w-4 h-4 top-0;
          }

          a {
            @apply no-underline hover:underline text-blue-dark-40 font-bold;
          }
          & + div {
            @apply md:ml-auto max-w-none;
          }
        }
      }
      .form-submit-group__button {
        @apply w-full mt-4;
        button {
          @apply w-full py-2 px-4;
        }
      }
    }
  }
  &-footer{
    @apply text-center p-6 md:py-8 md:px-10 bg-silver mt-4 -mx-6 md:-mx-10 -mb-6 md:-mb-10;
    a {
      @apply text-primary-0 no-underline hover:underline;
    }
  }
}
span.error-message-validate:empty {
  display: none;
}