.icon-link {
  &-item {
    @apply text-xl font-terpel-bold inline-block mr-5 mb-3;

    a {
      @apply flex items-center gap-2;

      span {
        @apply w-6 h-6 flex items-center;
      }
    }
  }
}
