.promo-content,
.map-block,
.certificate-block,
.media-library-block,
.form-block,
.common-block {
  @apply flex-col;

  &__section {
    .full-container {
      @apply flex flex-col gap-8 md:gap-[54px] py-6 md:py-8;
    }

    &.force-full-width {
      margin-left: -400px !important;
      margin-right: -400px !important;

      & > .full-container {
        @apply px-[14px] xs:px-8 sm:px-14 md:px-24 lg:px-[123px] w-screen mx-auto xl:container;

        @media screen and (min-width: 1366px) {
          max-width: 1366px !important;
        }
      }

      @media screen and (min-width: 1920px) {
        margin-left: calc(-50vw + 600px) !important;
        margin-right: calc(-50vw + 600px) !important;
      }
    }

    &-text {
      @apply flex flex-col gap-4 text-center text-neutral-100;

      &.text-align-left {
        @apply text-left;
      }
      p {
        @apply text-xl font-terpel-bold leading-[1.3];
      }
    }

    &-footer-text {
      @apply text-center text-xl leading-[1.3] font-terpel-bold text-neutral-100;

      &.text-align-left {
        @apply text-left;
      }
    }

  }
}
