table {
  @apply w-full border-collapse border-inherit indent-0 text-neutral-80 mb-4 last:mb-0;

  thead {
    @apply table-header-group text-[#183B56];

    tr th {
      @apply border border-y border-x-0 border-neutral-50 p-[10px];
    }

    &::after {
      content: "";
    }
  }

  tbody {
    tr {
      td {
        @apply p-0 border-2 md:border-4 border-white table-cell bg-neutral-5;

        &:first-child {
          @apply md:border-l-0;
        }

        &:last-child {
          @apply md:border-r-0;
        }
      }
    }
  }

  th {
    @apply p-2;

    div {
      @apply flex items-center justify-between;
    }
  }

  .card-landscape__content-list-item {
    @apply text-neutral-80;
  }
}

.no-styles > table {
  thead {
    tr th {
      @apply border-transparent;
    }
  }

  tbody {
    tr {
      td {
        @apply border-transparent bg-transparent;
      }
    }
  }
}

.has-padding {
  @apply 2md:px-4 lg:px-8 2lg:px-12 xl:px-[94px];
}
