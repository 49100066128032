.grid-promo {
	@apply grid grid-cols-1 gap-6 lg:grid-cols-2;
	&-card {
		$self: &;
		&-link{
			@apply flex gap-4 md:gap-6 items-start hover:text-primary-0 transition;
		}
		&-image {
			@apply w-32 md:w-44 xl:w-52 rounded-[10px] overflow-hidden aspect-[640/400];
		}
		&-title{
			@apply w-1 flex-grow text-sm md:text-lg xl:text-xl;
		}
		&:first-child{
			@apply row-span-3;
			#{$self}-link{
				@apply flex-col h-full;
			}
			#{$self}-image {
				@apply w-full lg:flex-grow;
				img {
					@apply w-full h-full object-center object-cover;
				}
			}
			#{$self}-title {
				@apply w-full flex-grow-0;
			}
		}
	}
}