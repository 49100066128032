.flexcards {
  &-wrapper {
    @apply grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8;
    &.column-3{
      @apply 
      grid-cols-1 md:grid-cols-3;
    }
    &.column-4{
      @apply grid-cols-2 lg:grid-cols-4;
    }
  }
  &-link {
    @apply flex flex-col;
  }
  &-article {
    @apply flex flex-col flex-grow rounded-[9px] md:rounded-2xl overflow-hidden;
    .altoque & {
      @apply shadow-[0px_4.01px_8.01px_0px_#00000021] relative;

      &:after {
        content: "";
        @apply h-[430px] w-full absolute left-0 opacity-10 -bottom-[277px];
        background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0.692708) 50.66%, rgba(0, 0, 0, 0) 100%);
      }
    }
  }
  &-info {
    @apply py-6 px-5 md:px-8 flex flex-col gap-3;
  }
  &-content{
    @apply relative;
  }
  &-icon {
    
    box-shadow: 0 4px 4px #00000026;
    @apply w-15 h-15 overflow-hidden rounded-full absolute left-8 bottom-full translate-y-1/3;
    img {
      @apply w-full h-full object-cover object-center;
    }
  }
  &-image {
    img {
      @apply md:h-[180px] w-full object-cover object-center rounded-[9px] md:rounded-b-2xl;
      .altoque & {
        @apply aspect-[165/101] md:aspect-auto;
      }
    }
  }
  &-title {
    @apply text-lg md:text-2xl;
  }
  &-description {
    .altoque & {
      @media(max-width: 767px){
        @apply text-[0.75rem] leading-[1.35];
      }
    }
  }
}