.accordion {
  @apply flex flex-col gap-[27px];

  &-item {
    @apply relative bg-white rounded-lg px-[44px] pb-5 flex flex-col; 

    &,
    &:not(:first-of-type) {
      @apply border border-solid border-neutral-50;
    }

    &::after {
      content: "";
      @apply absolute shrink-0 w-3 h-[6.67px] bg-auto bg-no-repeat right-8 top-[37px] translate-y-[-50%] z-10 transition duration-200 ease-in-out;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='6' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 0.896723C12 0.531277 11.67 0.244141 11.25 0.244141H0.75C0.33 0.244141 0 0.531277 0 0.896723C0 1.06639 0.075 1.20996 0.195 1.32743L5.445 6.54808C5.58 6.6786 5.775 6.76996 6 6.76996C6.225 6.76996 6.42 6.6786 6.555 6.54808L11.805 1.32743C11.925 1.20996 12 1.06639 12 0.896723Z' fill='%2342535c'/%3E%3C/svg%3E");
    }

    &.is-open::after {
      @apply rotate-180;
    }
  }

  &-header {
    @apply mb-0 relative flex items-center text-neutral-80 font-semibold text-left shadow-none rounded-none transition -mx-[44px] px-[44px] pt-[26px];
    width: calc(100% + 88px);

    &::after {
      @apply hidden;
    }

    &:not(.collapsed) {
      @apply shadow-none;
    }
  }

  &-body {
    @apply my-[11px] text-neutral-60 text-left;

    &,
    & p {
      @apply font-normal text-lg leading-relaxed;
    }

    a {
      @apply underline text-blue-dark-80 hover:text-blue-dark-20;
    }
  }

  &-collapse {
    transition: max-height 0.3s ease-out;
    overflow: hidden;
    max-height: 0;
    padding-right: 42px;

    &:not(.show) {
      display: block;
    }
  }
}
