.block-section {
  &.slide-products {
    @apply overflow-visible pb-0 lg:-mb-5;

    &::before {
      content: "";
      @apply -z-10 absolute w-screen left-0 -top-[300px] lg:-top-[500px] h-[170vw] md:h-[100vw] lg:h-[1450px];
    }

    &.has-background-gray::before {
      background: linear-gradient(to bottom, #f1f2fa00 5%, #f1f2fa, #f1f2fa, #f1f2fa 90%, #f1f2fa00);
    }

    &.has-background-light::before {
      background: linear-gradient(to bottom, #fff9ef00 5%, #fff9ef, #fff9ef, #fff9ef 90%, #fff9ef00);
    }

    .swiper-pagination {
      @apply relative p-1.5 mb-1 bg-[#E2E5EF] rounded-[1.90625rem] overflow-hidden hidden md:block md:max-w-xl lg:max-w-none;

      &__pill {
        @apply absolute px-6 py-[0.625rem] h-[50px] bg-white rounded-[1.59375rem] left-0 top-0 shadow-sm transition-all duration-700;
      }

      &__button-list {
        @apply relative z-10 flex w-full h-full flex-wrap justify-center;
      }

      &__button-item {
        @apply relative px-6 py-[0.625rem] text-[17px] leading-[30px] font-terpel-bold transition-colors duration-700;
      }
    }
  }
}
