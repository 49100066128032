.full-container--contact-cards {
  .card-landscape {
    &__image {
      @apply hidden;
    }
    &__content {
      @apply w-full;

      h2 {
        &,
        & + div {
          @apply text-center;
        }
      }

      &-list {
        @apply flex-row flex-wrap justify-center;
      }
    }
  }

  .grid-contact-cards {
    @apply grid grid-cols-1 gap-[20px];

    &__columns-2 {
      @apply md:grid-cols-2;
    }

    &__columns-3 {
      @apply md:grid-cols-2 lg:grid-cols-3;
    }

    &__columns-4 {
      @apply md:grid-cols-3 lg:grid-cols-4;
    }

    &__columns-5 {
      @apply md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5;
    }

    &__columns-6 {
      @apply md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6;
    }
  }
}
