.card-landscape {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);

    &.graymode {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    }

    &.graymode &__image {
        @apply p-[10px];

        img {
            @apply object-contain;
        }
    }

    @apply pb-6 sm:pb-[14px] p-[14px] rounded-2xl flex flex-col sm:flex-row gap-[10px] sm:gap-5 h-full;

    &__image {
        @apply flex bg-white rounded-2xl relative self-start;

        figure,
        img {
            @apply w-full;
        }

        img {
            @apply rounded-2xl shrink-0 object-cover object-center min-w-[214px] aspect-[237/192] sm:aspect-[214/251];
        }
    }

    &__content {
        @apply w-full flex flex-col gap-3 text-neutral-80 sm:py-4;

        &-is-centered {
            h2 {
                &,
                & + div {
                    @apply text-center;
                }
            }

            .icon-link-item {
                @apply flex justify-center;
            }
        }

        &-is-centered &-list {
            @apply flex-row flex-wrap justify-center;
        }

        .icon-link-item {
            @apply font-nunito text-base;

            a {
                @apply decoration-neutral-80;

                p {
                    @apply text-neutral-80;
                }
            }
        }

        h2 {
            @apply text-neutral-100 text-2xl leading-[1.3];
        }

        &-list {
            @apply flex flex-col gap-3 flex-1;

            &-item {
                @apply flex gap-0.5;

                &-description {
                    @apply text-black text-opacity-60;
                }

                p {
                    word-break: break-word;
                }

                a {
                    @apply hover:underline;
                    word-break: break-word;

                    &:not(:last-child) {
                        @apply pr-3;

                        &:after {
                            content: " / ";
                            @apply absolute mx-1;
                        }
                    }
                }

                address {
                    @apply not-italic;
                }
            }

            &-icon {
                @apply flex w-5 h-5 text-primary-0 shrink-0;

                &.icon-email {
                    @apply mt-px;
                }
            }
        }
    }
}
