.full-container--promo-card {
  .promo-content__section-content {
    @apply grid gap-7;

    .header-text {
      @apply text-xl font-terpel-bold leading-[1.3] text-neutral-100;
    }
  }
  .grid-promo-card {
    @apply grid grid-cols-1 sm:grid-cols-2 gap-[20px];

    &__columns-3 {
      @apply lg:grid-cols-3;
    }

    &__columns-4 {
      @apply md:grid-cols-3 lg:grid-cols-4;
    }

    &__columns-5 {
      @apply md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5;
    }
  }
}

.card-promo {
  @apply p-[14px] sm:p-5 rounded-xl;

  &.bg-white {
    @apply shadow-[0px_6px_20px_rgba(0,0,0,0.1)];
  }

  &__content {
    @apply flex flex-col gap-6 break-words grow;

    &-date {
      @apply flex grow items-end;
    }
  }
  &__image {
    @apply shrink-0 w-full rounded-xl object-cover object-center aspect-[320/215];
    &.square{
      @apply aspect-[1/1];
    }
    &.product{
      @apply aspect-[237/194];
    }
  }
  &__a {
    @apply flex flex-col h-full;
  }

  &__button {
    @apply hover:bg-opacity-70 rounded-[10px] absolute right-[14px] bottom-[14px] w-[49px] h-10 flex items-center justify-center;
  }

  &__icon {
    @apply text-neutral-0 flex items-center h-6 w-6;

    svg {
      @apply fill-transparent;
    }
  }
}
