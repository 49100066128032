.modal-images {
    @apply h-screen w-screen fixed top-0 left-0 z-20 flex flex-col justify-center items-center overflow-auto cursor-default;
    background: rgba(85, 88, 105, 0.75);
    
    &__close {
        @apply font-extrabold text-4xl pb-2 cursor-pointer text-neutral-5 hover:text-neutral-40;
    }
    &__animation-image {
        @apply hover:opacity-70;
        transition: .3s all;
    }
}
.animation-modal-images {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}
@-webkit-keyframes zoom {
    from {-webkit-transform:scale(0)} 
    to {-webkit-transform:scale(1)}
}
@keyframes zoom {
    from {transform:scale(0)} 
    to {transform:scale(1)}
}