// custom swiper - timeline
.filter {
  @apply w-full flex justify-end;
  &-content {
    @apply w-full sm:w-[225px] bg-white rounded-[8px];
    &__label {
      @apply block text-neutral-80 text-[12px];
    }
    &__select {
      @apply w-[96%] h-full border-transparent bg-transparent border-0 text-neutral-100 appearance-none bg-no-repeat bg-right-top bg-[length:10.67px_6.67px];
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 1c0-.28-.22-.5-.5-.5h-7a.495.495 0 0 0-.37.83l3.5 4c.09.1.22.17.37.17s.28-.07.37-.17l3.5-4c.08-.09.13-.2.13-.33Z' fill='%2342535c'/%3E%3C/svg%3E");
    }
  }
}

.timeline {
  @apply flex flex-col gap-8;
  &-container {
    @apply flex;
  }
  &-content {
    @apply grid pt-8 pb-[50px] relative;
    &::before {
      content: "";
      @apply absolute w-screen h-full bg-neutral-10 left-0 top-0;
      width: calc(100vw + 800px);
      margin-left: -50vw !important;
      margin-right: -50vw !important;
    }

    .banner-secondary-content {
      @apply py-0 gap-[14px] md:gap-[14px] 2xl:min-h-max 2xl:max-h-max;
    }
  }
  &-button {
    @apply text-white relative -mb-16 sm:mb-0 flex items-end sm:items-baseline;
    &.btnleft {
      @apply  left-[calc(50%-36px)] sm:left-0;
    }
    &.btnright {
      @apply right-[calc(50%-36px)] sm:right-0;
    }
    & > div {
      @apply p-1 bg-primary-0 opacity-80 rounded-lg mt-[39px];
    }
    &:disabled {
      & > div {
        @apply bg-neutral-40 text-neutral-60;
      }
    }
  }
  &-swiper {
    list-style-type: none;
    @apply items-center w-full flex mx-0 sm:mx-[20px] p-0 text-center #{!important};
    &--bar {
      @apply w-full mx-auto h-[10px] right-0 left-0 top-[50px] rounded-[20px] bg-neutral-50 absolute;
      &__loading {
        @apply bg-primary-0 h-[10px] rounded-[20px];
      }
    }
    &--item {
      @apply text-neutral-50 grid justify-center;
      &::before {
        content: " ";
        position: absolute;
      }
      &__figure {
        @apply flex items-center mt-[16px] mb-[25px];
        &--point {
          @apply flex z-10 justify-center items-center w-[25px] h-[26px] bg-neutral-50 rounded-full ring-4 ring-white shrink-0 cursor-pointer;
        }
        &--bar {
          @apply flex w-full bg-gray-200 h-[10px];
        }
      }
      &__description {
        @apply w-[140px] pl-[5px] pr-[9px];
      }
    }
  }
}

.beforeActivePoint {
  &::before {
    @apply bg-primary-0;
    content: " ";
    position: absolute;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    top: 50px;
    width: 100%;
    height: 10px;
    right: 58%;
  }
}

.activePoint {
  .timeline-swiper--item {
    @apply text-neutral-100;
  }
  .timeline-swiper--item__figure--point {
    @apply bg-primary-0;
  }
}
