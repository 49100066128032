.slider-banner {
  &-card {
    @apply flex gap-x-[16px] rounded-2xl min-h-[355px];
    &.image-rounded:not(.noExpanded) {
      @apply items-center;
    }

    &__image {
      @apply w-[48.65%] h-full aspect-[360/355] grow;
      .image-rounded:not(.noExpanded) & {
        @apply aspect-auto h-auto;
      }

      img {
        @apply object-cover object-center w-full h-full rounded-l-2xl;
        .image-rounded:not(.noExpanded) & {
          @apply aspect-square h-auto m-auto rounded-full w-4/5;
        }
      }
    }

    &__content {
      @apply w-[51.35%] px-7 pt-16 pb-8 grow;

      @media screen and (min-width: 900px) and (max-width: 1119px), (min-width: 1280px) {
        @apply pt-[81.22px] pb-[38px] px-[36px];
      }

      &--title {
        @apply mb-[12.59px] text-white;

        span {
          sup {
            @apply text-[40%] top-[-1em] text-inherit;
          }
          sub {
            @apply text-[40%] top-[0em] text-inherit;
          }
        }
      }

      &--description {
        @apply mb-[46.35px] text-white;

        @media screen and (min-width: 540px) {
          @apply pr-8;
        }
      }

      &--button {
        &-default {
          @apply inline-block;
        }

        &-float {
          @apply absolute right-[-5px] bottom-[-6px] w-[44px] h-[44px] items-center justify-center bg-primary-0 text-white rounded-2xl;
        }
      }
    }

    &__info {
      & > * {
        @apply grow;
      }

      &--body {
        @apply pt-8;
      }

      .full-container {
        &--card-vertical__footer-text {
          @apply font-nunito text-base leading-[1.4];
        }
        &-cta {
          @apply flex items-center max-w-[200px] shrink-0;

          a {
            @apply flex;
          }
        }
        &--info-card {
          &.is-float {
            @apply flex-row flex-wrap;

            .grid-infocard {
              @apply grow;
            }

            .full-container {
              &--card-vertical__footer-text {
                @apply w-3/4 grow text-left flex items-center;
              }
            }
          }
        }
      }
    }

    &:not(.noExpanded) {
      @media screen and (max-width: 539px) {
        @apply block;

        figure {
          @apply w-full h-auto aspect-video;

          img {
            @apply rounded-t-2xl rounded-b-none;
          }
        }

        .slider-banner-card__content {
          @apply px-[14px] py-8 w-full;
        }
      }
    }

    &.bg-transparent,
    &.bg-white,
    &.bg-neutral-0,
    &.bg-neutral-5,
    &.bg-neutral-10,
    &.bg-primary-light-80,
    &.bg-primary-light-100,
    &.bg-yellow-0,
    &.bg-yellow-light-40,
    &.bg-yellow-light-80,
    &.bg-yellow-dark-80,
    &.bg-blue-light-80,
    &.bg-blue-light-100 {
      .slider-banner-card {
        &__content {
          &--title {
            @apply text-neutral-90;
          }
          &--description {
            @apply text-neutral-80;
          }
        }
      }
    }
  }
}

.slider-banner-card.noExpanded {
  @apply p-5 rounded-2xl overflow-hidden relative h-full min-h-[355px] 2md:w-[300px] 2lg:w-[360px] flex;

  &::before {
    content: "";
    @apply absolute inset-0 w-full h-full rounded-2xl;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #262641 100%);
    mix-blend-mode: multiply;
    z-index: 1;
  }

  .slider-banner-card__image {
    @apply absolute inset-0 w-full h-full z-0;

    img {
      @apply w-full h-full object-cover object-center;
    }
  }

  .slider-banner-card__content {
    @apply flex flex-col gap-y-2 relative self-end p-0 grow z-10;

    &--title {
      @apply m-0 text-white;
    }

    &--description {
      @apply m-0 p-0 pl-[1.92px] pr-[8.19px] mb-4 text-white;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      max-height: calc(1.4em * 3);
    }

    &--button {
      &-default {
        @apply hidden;
      }
      &-float {
        @apply flex;
      }
    }
  }
}

.tab-panel {
  &.active {
    span {
      @apply border-b-2 md:border-b-4 text-neutral-100 font-terpel-extrabold;
    }
  }
}
