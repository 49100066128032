.form-block {
  &__section {
    &-content {
      @apply 2md:px-16 lg:px-24 2lg:px-36 xl:px-[190px];

      &--bottom {
        @apply 2md:-mx-16 lg:-mx-24 2lg:-mx-36 xl:-mx-[190px] mt-12;
    
        .card-landscape {
          &__content {
            h2 {
              @apply text-base md:text-lg lg:text-xl leading-[1.3];
            }
          }
        }
      }
    }
  }
}
