.align-left {
  @apply mb-4 float-left mr-4;
}
.align-right {
  @apply mb-4 float-right ml-4;
}
.align-center {
  @apply mb-4;
  &,
  & figure {
    @apply flex justify-center my-4;
  }

  &.ibexa-embed-type-image {
    &--small,
    &--medium {
      @apply mx-auto;
    }
  }
}

.embed-container {
  &,
  & iframe {
    @apply max-w-full;
  }
}

.embed-container,
.ibexa-embed-type-image--original,
.grid-infocard {
  @apply mb-4 last:mb-0;
}

.ibexa-embed-type-image {
  &--small,
  &--medium,
  &--large {
    figure,
    img {
      @apply w-full;
    }
  }

  &--small {
    @apply w-1/2 md:w-1/4;
  }
  &--medium {
    @apply w-3/4 md:w-1/2;
  }
  &--large {
    @apply w-full;
  }
}

img {
  @apply rounded-2xl;
}
