.error-page {
    &-container {
        @apply relative xl:container mx-auto px-4 md:px-8;
    }

    &-banner {
        @apply flex flex-col md:flex-row gap-[26px] md:gap-[20px] py-8 sm:px-8 lg:px-20 xl:px-[91px] lg:my-[68px];

        &__image {
            @apply lg:max-w-[570px] w-full md:w-[58%] grow flex justify-center lg:justify-start;

            figure {
                @apply py-1.5 px-16 xxs:px-20 md:px-6 xl:py-px xl:px-[62px] max-w-[570px];
            }

            img {
                @apply w-full object-cover object-center;
            }
        }

        &__content {
            @apply flex flex-col gap-6 md:gap-8 justify-center w-full md:w-[48%] lg:pl-8;

            h1 {
                @apply text-center md:text-left lg:text-[50px] text-primary-error;
            }

            &-description {
                @apply flex flex-col items-center gap-6 2md:pr-[50px];

                p {
                    @apply text-center md:text-left text-lg leading-[1.4] text-neutral-80;
                }
            }

            &-cta {
                @apply flex;

                .button {
                    @apply flex justify-center items-center py-4 mt-1.5 gap-[5px] w-full md:w-auto;
                }
            }
        }
    }
}
