*:not(.infocard-content__link, .icon-link-item, button, .nav-item) > a {
  @apply underline;
}

blockquote {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

pre {
  margin: 1em 0px;
  display: none;
}

sub,
sup {
  position: unset;
}

sub {
  vertical-align: sub;
}

sup {
  vertical-align: super;
}

p.has-shadow {
  @apply bg-white shadow-[0_6px_30px_rgba(0,0,0,0.1)] p-8 mb-5 rounded-3xl;
}
