.tabs-block {
  @apply flex-col py-8;

  &__section {
    & > .full-container {
      @apply flex flex-col gap-8 md:gap-[54px];
    }

    &.force-full-width {
      margin-left: -400px !important;
      margin-right: -400px !important;

      & > .full-container {
        @apply px-[14px] xs:px-8 sm:px-14 md:px-24 lg:px-[123px] w-screen mx-auto xl:container xl:max-w-[1366px];
      }

      @media screen and (min-width: 1920px) {
        margin-left: calc(-50vw + 600px) !important;
        margin-right: calc(-50vw + 600px) !important;
      }
    }

    &-text {
      @apply flex flex-col gap-4 text-center text-neutral-100;

      p {
        @apply text-xl font-terpel-bold leading-[1.3];
      }
    }
  }
}

.tabs-async {
  @apply flex flex-col gap-[17px] w-full;

  .tab-content > .tab-pane {
    color: black;

    .landing-page__zones {
      [data-ibexa-zone-id] > [data-ez-block-id] {
        &:last-child .full-container,
        .force-full-width {
          @apply pb-0;
        }
      }

      & div[data-ibexa-zone-id] {
        @apply w-full;
      }
    }

    #tabs-content {
      & > .richtext--content {
        @apply pt-6 md:pt-8;
      }
    }
  }

  &--scrollbar {
    scrollbar-width: none;
  }
}

.nav {
  &-tabs {
    @apply relative flex-grow flex flex-nowrap list-none;
    width: calc(100% - 4px);
  }

  &-item {
    @apply relative flex-grow z-10 min-w-[180px];
  }

  &-item > a {
    @apply no-underline;
  }

  &-link {
    @apply relative flex gap-2 items-center px-1.5 pt-1.5 pb-[25px] cursor-pointer h-full;

    h5 {
      @apply text-neutral-80;
    }

    &::before,
    &::after {
      content: "";
      @apply absolute inset-x-0 bottom-0 h-[3px] bg-neutral-50 rounded-full;
      width: calc(100% + 2px);
    }

    &::after {
      width: calc(100% - 30px);
    }

    &__icon {
      @apply flex shrink-0 justify-center items-center w-[38px] h-[38px] bg-neutral-50 text-white rounded-xl;
    }

    &:hover:not(.active) * {
      @apply opacity-75;
    }

    &:hover:not(.active) &__icon {
      @apply bg-primary-light-80 bg-opacity-75 text-primary-altoque-0 text-opacity-80;
    }

    &.active &__icon {
      @apply bg-primary-light-80 text-primary-0;
    }

    &.active {
      h5 {
        @apply font-terpel-extrabold;
      }

      &::after {
        @apply bg-primary-0;
      }
    }

    &.disabled:not(.active) {
      @apply pointer-events-none;

      h5 {
        @apply text-opacity-70;
      }
    }

    &.disabled:not(.active) &__icon {
      @apply bg-opacity-70;
    }
  }
}
