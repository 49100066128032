.media-library-block {
  &__section {
    &-content {
      @apply p-4 -mx-4 rounded;
    }
  }
}

.media-library {
  @apply flex flex-col md:flex-row gap-5;

  &__sidebar {
    @apply relative md:w-[200px] 2md:w-[240px] lg:w-[285px] shrink-0 flex flex-col gap-3;

    &::after {
      content: "";
      @apply absolute right-0 md:w-px bg-neutral-50 h-full;
    }

    &-title {
      @apply p-2 mt-1.5;

      h3 {
        @apply text-base leading-[1.4] font-nunito font-bold text-neutral-100;
      }
    }

    &-list {
      @apply flex md:flex-col gap-1 overflow-x-scroll pb-1;

      &::-webkit-scrollbar {
        @apply h-[6px];
      }

      &::-webkit-scrollbar-thumb {
        @apply rounded bg-neutral-50;
      }

      li {
        @apply flex shrink-0 grow gap-2 align-baseline min-h-[38px] bg-neutral-5 hover:bg-neutral-40 p-2 cursor-pointer;

        &.active {
          @apply bg-neutral-40;
        }
      }
    }
  }

  &__content {
    @apply w-full grow flex flex-col gap-8;

    &-view {
      @apply flex gap-4 justify-end;

      button {
        @apply flex items-center w-6 h-6 text-neutral-60 hover:text-primary-light-40;

        &.active {
          @apply text-primary-0;
        }
      }
    }
  }

  &__cards,
  &__error {
    @apply grid grid-cols-1 gap-5;

    &-skeleton {
      @apply xs:grid-cols-2 2lg:grid-cols-3;
    }

    .infocard {
      @apply p-[22px] pb-[41px];
    }
  }

  &__table {
    table {
      @apply w-full border-collapse border-inherit indent-0;

      thead {
        @apply relative hidden md:table-header-group;

        tr th {
          @apply border border-y border-x-0 border-neutral-50 p-[10px];
        }

        &::after {
          content: "";
        }
      }

      tbody {
        &.media-library-skeleton {
          tr td {
            @apply p-0 md:border-4 border-white bg-gray-100;
          }
        }

        tr {
          td {
            @apply p-0 md:border-4 border-white md:bg-neutral-5 flex md:table-cell;

            div:not(.cell-responsive) {
              @apply flex md:justify-center md:text-center bg-neutral-5 grow p-2 mb-1 ml-1 md:m-0;

              a {
                @apply flex gap-1 items-center;
              }
            }

            .cell-responsive {
              @apply flex md:hidden p-2 pb-3 border-r border-neutral-50 w-28 xxs:w-[129px] shrink-0 grow-0 text-sm leading-[1.4] font-bold items-center;
            }

            &:first-child {
              @apply md:border-l-0;
              div:not(.cell-responsive) {
                @apply justify-start text-left;
              }
            }

            &:last-child {
              @apply md:border-r-0;

              div:not(.cell-responsive) {
                @apply mb-0;
              }
            }
          }

          &:nth-child(odd) {
            div:not(.cell-responsive) {
              @apply bg-neutral-5 md:bg-opacity-0;
            }
          }
  
          &:nth-child(even) {
            div:not(.cell-responsive) {
              @apply bg-[#ECECEC] md:bg-opacity-0;
            }
          }
        }
      }

      th {
        @apply p-2;

        div {
          @apply flex items-center justify-between;
        }
      }
    }
  }
}
