.cert-form {
    @apply mx-auto flex flex-col gap-5 w-full 2md:w-[740px];

    &_box {
        @apply flex flex-col gap-2;

        &--content {
            @apply relative w-full py-[4.6px] px-4 bg-white border border-neutral-50 rounded-lg;

            & > label {
                @apply text-neutral-80 text-xs leading-[15.6px];
            }

            & > input {
                @apply w-full outline-none text-base leading-[22.4px] text-neutral-100 bg-transparent;
            }
        }
    }

    &_alert {
        @apply flex gap-2 text-sm;

        &--icon {
            @apply w-[18px] h-[18px] shrink-0;
        }
    }

    &_button {
        @apply inline-block w-[193px] font-medium;

        &.disabled {
            @apply text-neutral-80;
        }
    }
}

.error-validate {
    @apply bg-primary-light-100 border-primary-dark-80;

    & > label {
        @apply text-primary-dark-80;
    }
}
