.banner-secondary {
  @apply relative flex flex-col md:flex-row gap-[15px] md:gap-5;

  &-content {
    @apply flex flex-col justify-center gap-4 md:gap-8 px-[14px] pt-1.5 pb-[10px] md:px-8 rounded-2xl w-full md:w-[48.20%] grow 2xl:min-h-[312px] 2xl:max-h-[354px];

    &.bg-white,
    &.bg-neutral-0,
    &.bg-transparent,
    &.transparent {
      @apply pl-0 pr-0 md:pr-6;
    }

    &__title {
      @apply flex items-center gap-5 text-neutral-100;

      span {
        sup {
          @apply text-[40%] top-[-1em] text-inherit;
        }
        sub {
          @apply text-[40%] top-[0em] text-inherit;
        }
      }

      &--icon {
        @apply w-[69px] h-[69px] flex items-center text-current;
      }
    }
    &__description {
      @apply text-neutral-100;

      .ezrichtext-field {
        img {
          @apply mt-2 mb-7 max-w-full max-h-[70px] md:max-h-[90px] w-auto;
        }

        .align-left,
        .align-right,
        .align-center {
          &,
          img {
            @apply mb-2;
          }
        }
      }

      ul,
      ol {
        padding-inline-start: 20px !important;
      }
    }

    &__cta {
      @apply w-full md:w-auto mt-4 md:m-0;
    }
  }

  &-image {
    @apply w-full md:w-[51.80%] grow;

    figure {
      @apply h-full;

      img {
        @apply rounded-2xl object-cover w-full md:aspect-[570/312];
      }

      .modal-images {
        img {
          @apply aspect-auto
        }
      }
    }
  }

  &.is_reverse {
    @apply md:flex-row-reverse;

    & > .banner-secondary-content {
      &.bg-white,
      &.bg-neutral-0,
      &.bg-transparent,
      &.transparent {
        @apply pl-0 md:pl-6 pr-0 #{!important};
      }
    }
  }
}

.full-container--banner-secondary {
  &__footer-text {
    @apply text-center text-xl leading-[1.3] font-terpel-bold text-neutral-100 -mt-5;

    &.text-align-left {
      @apply text-left;
    }

    a {
      @apply text-primary-0 hover:underline;
    }
  }
}
