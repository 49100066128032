

























































































































































































































































.video-panel {
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    @apply bg-neutral-10;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    @apply bg-primary-0;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    @apply bg-primary-dark-40;
  }
}
