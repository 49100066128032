.promo-content {
  @apply flex-col;

  &__section {
    .full-container {
      &.carousel-aside {
        @apply md:flex-row;

        & > div {
          @apply w-full md:w-1/2 flex-grow;
        }
      }

      &--search {
        .promo-content__section-text p {
          @apply text-[22px];
        }
      }
    }
  }

  &__section.force-full-width .full-container--banner {
    @apply px-[14px] xs:px-8;
  }
}
